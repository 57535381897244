export const COMPANY_TYPES = [
    { value: 1, text: 'Label.Company' },
    { value: 2, text: 'Label.SinglePerson' },
];

export const CONTACT_TITLES = [
    { value: 1, text: 'Label.Mr' },
    { value: 2, text: 'Label.Ms' },
    { value: 3, text: 'Label.Family' },
];

export const TAG_TYPES = [
    { value: 1, text: 'Label.Customer' },
    { value: 2, text: 'Label.ServiceObject' },
    { value: 3, text: 'Label.Job' },
    { value: 4, text: 'Label.Capabilities' },
];

export const USER_GENDERS = [
    { value: 1, text: 'Label.Male' },
    { value: 2, text: 'Label.Female' },
];

export const USER_CIVILSTATUSES = [
    { value: 1, text: 'Label.Single' },
    { value: 2, text: 'Label.Married' },
];

export const JOB_STATES = [
    { value: 1, text: 'Label.Created', color: '#000000', icon: 'playlist_add' },
    {
        value: 2,
        text: 'Label.ReadyForScheduling',
        color: '#6d4c41',
        icon: 'event_available',
    },
    { value: 3, text: 'Label.Scheduled', color: '#1565c0', icon: 'event_note' },
    {
        value: 4,
        text: 'Label.Released',
        color: '#43a047',
        icon: 'playlist_add_check',
    },
    {
        value: 5,
        text: 'Label.InProgress',
        color: '#ffab40',
        icon: 'rotate_right',
    },
    { value: 6, text: 'Label.WorkDone', color: '#558b2f', icon: 'done' },
    { value: 7, text: 'Label.Closed', color: '#9e9e9e', icon: 'done_all' },
    { value: 8, text: 'Label.Driving', color: '#ffab40', icon: 'drive_eta' },
    { value: 9, text: 'Label.Working', color: '#ffab40', icon: 'build' },
];

export const JOB_FILTER_STATES = [
    {
        value: 1,
        text: 'Label.MyJobs',
        icon: 'person_pin',
        color: '#616161',
        selected: false,
    },
    {
        value: 2,
        text: 'Label.UnassignedJobs',
        icon: 'event_available',
        color: '#8e24aa',
        selected: false,
    },
    {
        value: 6,
        text: 'Label.HasWorkDoneFor',
        icon: 'playlist_add_check',
        color: '#6d4c41',
        selected: false,
    },
    {
        value: 7,
        text: 'Label.Closed',
        icon: 'done_all',
        color: '#9e9e9e',
        selected: false,
    },
];

export const DOCUMENT_TYPES = [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
];

export const MIME_TYPES = {
    png: 'image/png',
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpg',
    pdf: 'application/pdf',
};

export const TAG_COLORS = [
    { id: 1, value: '#1a237e' },
    { id: 2, value: '#00796b' },
    { id: 3, value: '#33691e' },
    { id: 4, value: '#e65100' },
    { id: 5, value: '#4e342e' },
    { id: 6, value: '#424242' },
    { id: 7, value: '#827717' },
    { id: 8, value: '#ff6f00' },
    { id: 9, value: '#0288d1' },
    { id: 10, value: '#00acc1' },
    { id: 11, value: '#b71c1c' },
    { id: 12, value: '#d81b60' },
    { id: 13, value: '#000000' },
    { id: 14, value: '#558b2f' },
    { id: 15, value: '#5e35b1' },
    { id: 16, value: '#6d4c41' },
];

export const TIME_EVENTS_TYPES = [
    {
        value: 1,
        text: 'Label.WorkingTime',
        icon: 'directions_walk',
        color: '#1e88e5',
    },
    {
        value: 2,
        text: 'Label.DrivingTime',
        icon: 'drive_eta',
        color: '#6eb72d',
    },
    { value: 3, text: 'Label.WorkshopTime', icon: 'build', color: '#dcb72d' },
    { value: 4, text: 'Label.Vacation', icon: 'rowing', color: '#ff9900' },
    {
        value: 5,
        text: 'Label.Illness',
        icon: 'airline_seat_individual_suite',
        color: '#f08080',
    },
];

export const CHECKLIST_STEP_TYPES = [
    { value: 0, text: 'Label.Root', icon: 'info' },
    { value: 1, text: 'Label.ChoiceStep', icon: 'search' },
    { value: 2, text: 'Label.EnterValueStep', icon: 'text_fields' },
    { value: 3, text: 'Label.MaterialStep', icon: 'build' },
    { value: 4, text: 'Label.PhotoStep', icon: 'photo' },
    { value: 5, text: 'Label.GroupStep', icon: 'folder' },
    { value: 6, text: 'Label.SignatureStep', icon: 'edit' },
];

export const CHECKLIST_STEP_CHOICE_TYPES = [
    { value: 1, text: 'Label.SingleChoice', icon: '' },
    { value: 2, text: 'Label.MultipleChoice', icon: '' },
];

export const CHECKLIST_STEP_VALUE_TYPES = [
    { value: 1, text: 'Label.Text', icon: '' },
    { value: 2, text: 'Label.QuickChoice', icon: '' },
    { value: 3, text: 'Label.Date', icon: 'date_range' },
    { value: 4, text: 'Label.Time', icon: 'access_time' },
    { value: 5, text: 'Label.Barcode', icon: 'ballot' },
];

export const ITEM_TYPES = [
    { value: 1, text: 'Label.Material' },
    { value: 2, text: 'Label.Service' },
    { value: 3, text: 'Label.Equipment' },
    { value: 4, text: 'Label.TextTemplate' },
];

export const JOB_EVENT_TYPES = [
    { value: 1, text: 'Label.UserCreatedJob' },
    { value: 2, text: 'Label.UserCreatedAppointment' },
    { value: 3, text: 'Label.UserDeletedAppointment' },
    { value: 4, text: 'Label.UserCommentedJob' },
    { value: 5, text: 'Label.UserChangedJobStatus' },
];

export const MAINTENANCE_EVENT_INTERVAL = [
    { value: 1, text: 'Label.Daily' },
    { value: 2, text: 'Label.Weekly' },
    { value: 3, text: 'Label.Monthly' },
    { value: 4, text: 'Label.Yearly' },
];

export const APPOINTMENTS_TYPES = [
    {
        value: 1,
        text: 'Label.Appointment',
        icon: 'date_range',
        color: '#6eb72d',
        color1: '#00aae8',
    },
    {
        value: 2,
        text: 'Label.Illness',
        icon: 'airline_seat_individual_suite',
        color: '#fdcdcd',
    },
    { value: 3, text: 'Label.Vacation', icon: 'rowing', color: '#c9dcfc' },
    { value: 4, text: 'Label.Other', icon: 'perm_identity', color: '#3366cc' },
];
