// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// service
import { User } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class UserService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all users
    */
    getAll(): Observable<User[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/user/all?' + params.toString(), {})
            .pipe(map(res => res.users));
    }
    /*
        get user list
    */
    getList(): Observable<User[]> {
        return this.storageService.getDataList('users').pipe(
            tap(res => {
                res.forEach((_user: User) => {
                    if (_user.image == null) {
                        _user.image = 'assets/images/profile.png';
                    }
                    _user.timeTrackingLink = [
                        '/',
                        'tools',
                        'time-tracking',
                        'users-time',
                        _user.id,
                    ];
                });
            }),
        );
    }
    /*
        get user by id
    */
    getItemById(_id: any): Observable<User> {
        return this.storageService.getDataItem('users', _id).pipe(
            tap(res => {
                if (res.image == null) {
                    res.image = 'assets/images/profile.png';
                }
            }),
        );
    }
}
