// angular
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

// service
import { Serviceobject } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class ServiceobjectService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all serviceobjects
    */
    getAll(): Observable<Serviceobject[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/serviceobject/all?' + params.toString(), {})
            .pipe(map(res => res.serviceobjects));
    }
    /*
        get serviceobject list
    */
    getList(): Observable<Serviceobject[]> {
        return this.storageService.getDataList('serviceobjects');
    }
    /*
        get serviceobject by id
    */
    getItemById(_id: any): Observable<Serviceobject> {
        return this.storageService.getDataItem('serviceobjects', _id).pipe(
            switchMap((_serviceobject: Serviceobject) => {
                return forkJoin(
                    this.storageService.getDataList('products'),
                    this.storageService.getDataList('users'),
                    this.storageService.getDataList('checklist_steps'),
                ).pipe(
                    tap(_res => {
                        const _products = _res[0];
                        const _users = _res[1];
                        const _checklistSteps = _res[2];
                        _serviceobject.products = _products.filter(
                            _item =>
                                _item.serviceobject_id == _serviceobject.id,
                        );
                        _serviceobject.documents.forEach(_document => {
                            if (_document.upload_user_id != null) {
                                _document.upload_user = _users.find(
                                    _item =>
                                        _item.id == _document.upload_user_id,
                                );
                            }
                        });
                        _serviceobject.products.forEach(_product => {
                            _product.checklist_steps = _checklistSteps.filter(
                                _item => _item.product_id == _product.id,
                            );
                        });
                    }),
                    map(() => _serviceobject),
                );
            }),
        );
    }
    /*
        update service object proudcts
        _mode - 0: update, 1: add, 2: delete
    */
    updateProducts(
        _id: any,
        _productIds: any[],
        _mode?: number,
    ): Observable<any> {
        const mode = _mode ? _mode : 0;
        if (mode == 1) {
            return this.storageService.getDataList('checklist_steps').pipe(
                switchMap(_steps => {
                    const _productRequests = [];
                    _productIds.forEach(_productId => {
                        _productRequests.push(
                            this.storageService.getDataItem(
                                'products',
                                _productId,
                            ),
                        );
                    });
                    return forkJoin(_productRequests).pipe(
                        switchMap(_products => {
                            const _createRequests = [];
                            _products.forEach((_product: any) => {
                                const _newProduct = JSON.parse(
                                    JSON.stringify(_product),
                                );
                                _newProduct['serviceobject_id'] = _id;
                                _createRequests.push(
                                    this.storageService
                                        .createDataItem(
                                            'products',
                                            _newProduct,
                                            _newProduct,
                                            true,
                                        )
                                        .pipe(
                                            tap(_created => {
                                                const _clonedSteps = JSON.parse(
                                                    JSON.stringify(_steps),
                                                );
                                                _created.checklist_steps = _clonedSteps.filter(
                                                    _item =>
                                                        _item.product_id ==
                                                        _product.id,
                                                );
                                            }),
                                        ),
                                );
                            });
                            return forkJoin(_createRequests).pipe(
                                switchMap(_creates => {
                                    const _addRequests = [];
                                    _creates.forEach((_product: any) => {
                                        _product.checklist_steps.forEach(
                                            _step => {
                                                const _oldId = _step.id;
                                                _step.id = uuid();
                                                _step.product_id = _product.id;
                                                _product.checklist_steps.forEach(
                                                    _item => {
                                                        if (
                                                            _item.parent_step ==
                                                            _oldId
                                                        ) {
                                                            _item.parent_step =
                                                                _step.id;
                                                        }
                                                    },
                                                );
                                                _step.checklist_step_choices.forEach(
                                                    _choice => {
                                                        _choice.id = uuid();
                                                        _choice.step_id =
                                                            _step.id;
                                                    },
                                                );
                                                _step.checklist_step_values.forEach(
                                                    _value => {
                                                        _value.id = uuid();
                                                        _value.step_id =
                                                            _step.id;
                                                    },
                                                );
                                                _step.checklist_step_attachments = [];
                                            },
                                        );
                                        _addRequests.push(
                                            this.storageService.createAction(
                                                'checklist_steps_list',
                                                'add',
                                                {
                                                    checklist_steps:
                                                        _product.checklist_steps,
                                                },
                                            ),
                                        );
                                        _steps.push(
                                            ..._product.checklist_steps,
                                        );
                                    });
                                    return forkJoin(_addRequests).pipe(
                                        switchMap(() =>
                                            this.storageService
                                                .setDataList(
                                                    'checklist_steps',
                                                    _steps,
                                                )
                                                .pipe(map(() => _creates)),
                                        ),
                                    );
                                }),
                            );
                        }),
                    );
                }),
            );
        } else {
            return of(null);
        }
    }
}
