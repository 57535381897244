import { NgModule } from '@angular/core';

import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';

import { AlertDialog } from './alert/alert.dialog';
import { AttachmentDialog } from './attachment/attachment.dialog';
import { ChangeLanguageDialog } from './change-language/change-language.dialog';
import { ChecklistSelectDialog } from './checklist-select/checklist-select.dialog';
import { ConfirmDialog } from './confirm/confirm.dialog';
import { ContactsDialog } from './contacts/contacts.dialog';
import { DocumentPreviewDialog } from './document-preview/document-preview.dialog';
import { DocumentsDialog } from './documents/documents.dialog';
import { JobItemDialog } from './job-item/job-item.dialog';
import { JobMessagesDialog } from './job-messages/job-messages.dialog';
import { JobStateDialog } from './job-state/job-state.dialog';
import { JobTagDialog } from './job-tag/job-tag.dialog';
import { JobTimeEventDialog } from './job-time-event/job-time-event.dialog';
import { JobTimeTrackingDialog } from './job-time-tracking/job-time-tracking.dialog';
import { ProductSelectDialog } from './product-select/product-select.dialog';
import { ProductStateDialog } from './product-state/product-state.dialog';
import { RenameDialog } from './rename/rename.dialog';
import { SignatureDialog } from './signature/signature.dialog';
import { SyncDataDialog } from './sync-data/sync-data.dialog';

@NgModule({
    imports: [SharedModule, PipesModule, ComponentsModule],
    declarations: [
        AlertDialog,
        AttachmentDialog,
        ChangeLanguageDialog,
        ChecklistSelectDialog,
        ConfirmDialog,
        ContactsDialog,
        DocumentPreviewDialog,
        DocumentsDialog,
        JobItemDialog,
        JobMessagesDialog,
        JobStateDialog,
        JobTagDialog,
        JobTimeEventDialog,
        JobTimeTrackingDialog,
        ProductSelectDialog,
        ProductStateDialog,
        RenameDialog,
        SignatureDialog,
        SyncDataDialog,
    ],
    entryComponents: [
        AlertDialog,
        AttachmentDialog,
        ChangeLanguageDialog,
        ChecklistSelectDialog,
        ConfirmDialog,
        ContactsDialog,
        DocumentPreviewDialog,
        DocumentsDialog,
        JobItemDialog,
        JobMessagesDialog,
        JobStateDialog,
        JobTagDialog,
        JobTimeEventDialog,
        JobTimeTrackingDialog,
        ProductSelectDialog,
        ProductStateDialog,
        RenameDialog,
        SignatureDialog,
        SyncDataDialog,
    ],
    exports: [
        AlertDialog,
        AttachmentDialog,
        ChangeLanguageDialog,
        ChecklistSelectDialog,
        ConfirmDialog,
        ContactsDialog,
        DocumentPreviewDialog,
        DocumentsDialog,
        JobItemDialog,
        JobMessagesDialog,
        JobStateDialog,
        JobTagDialog,
        JobTimeEventDialog,
        JobTimeTrackingDialog,
        ProductSelectDialog,
        ProductStateDialog,
        RenameDialog,
        SignatureDialog,
        SyncDataDialog,
    ],
})
export class DialogsModule {}
