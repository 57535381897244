import {
    OnInit,
    OnDestroy,
    AfterViewInit,
    Component,
    Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import SignaturePad from 'signature_pad';

import { MIME_TYPES } from '../../app.constant';
import {
    ChecklistStep,
    ChecklistStepAttachment,
    Job,
} from '../../app.interface';
import {
    ChecklistStepService,
    EventService,
    CacheService,
} from '../../services';

@Component({
    selector: 'app-signature-dialog',
    templateUrl: './signature.dialog.html',
    styleUrls: [],
})
export class SignatureDialog implements OnInit, OnDestroy, AfterViewInit {
    loading = false;
    job: Job;
    step: ChecklistStep;
    form: FormGroup;
    signaturePad: SignaturePad;
    attachment: ChecklistStepAttachment;
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        private checklistStepService: ChecklistStepService,
        private eventService: EventService,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<SignatureDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.form = this.fb.group({
            name: [this.step.name, Validators.compose([Validators.required])],
        });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        refresh data
    */
    refreshData(): void {
        const _syncIds = this.cacheService.getDataList('sync_ids');
        if (this.data.job) {
            this.data.job.id = _syncIds[this.data.job.id]
                ? _syncIds[this.data.job.id]
                : this.data.job.id;
            this.job = this.data.job;
        }
        if (this.data.checklistStep) {
            this.data.checklistStep.id = _syncIds[this.data.checklistStep.id]
                ? _syncIds[this.data.checklistStep.id]
                : this.data.checklistStep.id;
            this.step = this.data.checklistStep;
            if (this.step.checklist_step_attachments.length > 0) {
                this.attachment = this.step.checklist_step_attachments[0];
                this.attachment.mimeType =
                    MIME_TYPES[this.attachment.original_name.split('.').pop()];
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.step.checklist_step_attachments.length == 0) {
            this.initPad();
        }
    }
    /*
        initialize signature pad
    */
    initPad(): void {
        const canvas = document.querySelector('canvas');
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        this.signaturePad = new SignaturePad(canvas);
    }

    onSubmit(): void {
        if (this.form.valid) {
            const _stepData: ChecklistStep = {
                id: this.step.id,
                name: this.form.value.name,
            };
            this.step.name = this.form.value.name;
            if (!this.attachment) {
                if (this.signaturePad.isEmpty()) {
                    this.eventService.sendEvent('open_alert_dialog', {
                        message: 'ValidationMessage.SignatureRequired',
                        callback: null,
                    });
                    return;
                }
                if (this.step.checklist_step_attachments.length > 0) {
                    _stepData.checklist_step_attachments = [];
                    this.step.checklist_step_attachments = [];
                }
            }
            const imgData = this.signaturePad.toDataURL('image/png');
            this.loading = true;
            this.spinner.show();
            this.checklistStepService
                .updateItem(_stepData, this.step)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                        this.spinner.hide();
                    }),
                )
                .subscribe(() => {
                    if (!this.attachment) {
                        this.checklistStepService
                            .uploadAttachments(this.step, [
                                {
                                    name: 'signature.png',
                                    type: 'image/png',
                                    src: imgData,
                                },
                            ])
                            .subscribe(() => {
                                this.dialogRef.close({
                                    refresh: true,
                                });
                            });
                    } else {
                        this.dialogRef.close({
                            refresh: true,
                        });
                    }
                });
        }
    }

    urltoFile(url: string, filename: string, mimeType: string): Promise<any> {
        return fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType });
            });
    }
    /*
        called when user click 'clear' button
    */
    clear(): void {
        if (this.attachment) {
            this.attachment = null;
            setTimeout(() => {
                this.initPad();
            }, 100);
        } else {
            this.signaturePad.clear();
        }
    }
    /*
        called when user click cancel button
    */
    cancel(): void {
        this.dialogRef.close();
    }
}
