// angular
import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad,
    Route,
} from '@angular/router';
// services
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from './auth.service';
import { RoleService } from './role.service';

@Injectable()
export class GuardService implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private authService: AuthService,
        private roleService: RoleService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): boolean {
        let url = `/${route.path}`;
        if (
            route.path === 'jobs' ||
            route.path === 'contacts' ||
            route.path === 'settings' ||
            route.path === 'time-tracking'
        ) {
            url = '/main' + url;
        }
        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        const jwt = new JwtHelperService();
        if (!jwt.isTokenExpired(this.authService.getToken())) {
            if (url == '/main/jobs') {
                if (
                    !this.roleService.hasPermission(
                        RoleService.VIEW_ALL_JOBS,
                    ) &&
                    !this.roleService.hasPermission(RoleService.VIEW_OWN_JOBS)
                ) {
                    this.router.navigate(['/main/settings']);
                    return false;
                }
            }
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/login'], { queryParams: { return: url } });
        return false;
    }
}
