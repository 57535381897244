import { NgZone, OnDestroy, OnInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription, Observable } from 'rxjs';

import { User } from '../../app.interface';
import {
    AlertDialog,
    AttachmentDialog,
    ChangeLanguageDialog,
    ChecklistSelectDialog,
    ConfirmDialog,
    ContactsDialog,
    DocumentPreviewDialog,
    DocumentsDialog,
    JobItemDialog,
    JobMessagesDialog,
    JobStateDialog,
    JobTagDialog,
    JobTimeEventDialog,
    JobTimeTrackingDialog,
    ProductSelectDialog,
    ProductStateDialog,
    RenameDialog,
    SignatureDialog,
    SyncDataDialog,
} from '../../dialogs';
import { AuthService, EventService, ConnectionService } from '../../services';

/** @title Responsive sidenav */
@Component({
    selector: 'app-full-layout',
    templateUrl: 'full.component.html',
    styleUrls: [],
})
export class FullComponent implements OnInit, OnDestroy {
    @ViewChild('snav', { static: true }) public sideNav: MatSidenav;
    userInfo: User;
    eventSubscription: Subscription;
    public config: PerfectScrollbarConfigInterface = {};

    constructor(
        private zone: NgZone,
        private router: Router,
        private eventService: EventService,
        private authService: AuthService,
        private connectionService: ConnectionService,
        private dialog: MatDialog,
    ) {
        /*
            event listenr
        */
        this.eventSubscription = this.eventService
            .getEvent()
            .subscribe(payload => {
                this.zone.run(() => {
                    switch (payload.command) {
                        case 'open_alert_dialog':
                            this.openDialog(AlertDialog, {
                                message: payload.data.message,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_attachment_dialog':
                            this.openDialog(AttachmentDialog, {
                                attachment: payload.data.attachment,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_change_language_dialog':
                            this.openDialog(ChangeLanguageDialog, {}).subscribe(
                                result => {
                                    if (payload.data.callback)
                                        payload.data.callback(result);
                                },
                            );
                            break;
                        case 'open_checklist_select_dialog':
                            this.openDialog(ChecklistSelectDialog, {
                                job: payload.data.job,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_confirm_dialog':
                            this.openDialog(ConfirmDialog, {
                                message: payload.data.message,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_contacts_dialog':
                            this.openDialog(ContactsDialog, {
                                contacts: payload.data.contacts,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_document_preview_dialog':
                            this.openDialog(DocumentPreviewDialog, {
                                document: payload.data.document,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_documents_dialog':
                            this.openDialog(DocumentsDialog, {
                                jobId: payload.data.jobId,
                                serviceobjectId: payload.data.serviceobjectId,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_job_item_dialog':
                            this.openDialog(JobItemDialog, {
                                jobItem: payload.data.jobItem,
                                job: payload.data.job,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_job_messages_dialog':
                            this.openDialog(JobMessagesDialog, {
                                job: payload.data.job,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_job_state_dialog':
                            this.openDialog(JobStateDialog, {
                                job: payload.data.job,
                                action: payload.data.action,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_job_tag_dialog':
                            this.openDialog(JobTagDialog, {
                                job: payload.data.job,
                                tags: payload.data.tags,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_job_time_event_dialog':
                            this.openDialog(JobTimeEventDialog, {
                                job: payload.data.job,
                                timeEvent: payload.data.timeEvent,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_job_time_tracking_dialog':
                            this.openDialog(JobTimeTrackingDialog, {
                                job: payload.data.job,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_product_select_dialog':
                            this.openDialog(ProductSelectDialog, {}).subscribe(
                                result => {
                                    if (payload.data.callback)
                                        payload.data.callback(result);
                                },
                            );
                            break;
                        case 'open_product_state_dialog':
                            this.openDialog(ProductStateDialog, {
                                product: payload.data.product,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_rename_dialog':
                            this.openDialog(RenameDialog, {
                                name: payload.data.name,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_signature_dialog':
                            this.openDialog(SignatureDialog, {
                                job: payload.data.job,
                                checklistStep: payload.data.checklistStep,
                            }).subscribe(result => {
                                if (payload.data.callback)
                                    payload.data.callback(result);
                            });
                            break;
                        case 'open_sync_data_dialog':
                            this.openDialog(SyncDataDialog, {}).subscribe(
                                result => {
                                    if (payload.data.callback)
                                        payload.data.callback(result);
                                },
                            );
                            break;
                        case 'toggle_sidenav':
                            this.sideNav.toggle();
                            break;
                        case 'sign_out':
                            this.authService.logout();
                            this.router.navigate(['/', 'login'], {
                                replaceUrl: true,
                            });
                            break;
                        case 'updated_user':
                            this.userInfo = this.authService.getUserInfo();
                            break;
                    }
                });
            });
    }

    ngOnInit(): void {
        this.userInfo = this.authService.getUserInfo();

        if (this.connectionService.getConnected()) {
            this.eventService.sendEvent('open_sync_data_dialog', {
                callback: null,
            });
        }
    }

    ngOnDestroy(): void {
        this.eventSubscription.unsubscribe();
    }
    /*
        open dialog
    */
    openDialog(_dialog: any, _data?: any, _mode?: string): Observable<any> {
        const dialogRef = this.dialog.open(_dialog, {
            width: _mode == 'large' ? '1920px' : '650px',
            disableClose: true,
            autoFocus: false,
            data: _data,
            panelClass: 'mobile-dialog-pane',
        });
        return dialogRef.afterClosed();
    }
}
