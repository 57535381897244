import { OnDestroy, OnInit, NgZone, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { Language } from '../../app.interface';
import {
    RequestService,
    CacheService,
    EventService,
    UtilService,
    ConfigService,
} from '../../services';

@Component({
    selector: 'app-change-language-dialog',
    templateUrl: './change-language.dialog.html',
    styleUrls: [],
})
export class ChangeLanguageDialog implements OnInit, OnDestroy {
    loading = false;
    languages: Language[] = ConfigService.settings.i18n.availableLanguages;
    protected _onDestroy = new Subject<void>();
    constructor(
        private zone: NgZone,
        private translateService: TranslateService,
        private cacheService: CacheService,
        private requestService: RequestService,
        private eventService: EventService,
        private utilService: UtilService,
        public dialogRef: MatDialogRef<ChangeLanguageDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.languages.forEach(_lang => {
            if (_lang.code == this.translateService.currentLang) {
                _lang.selected = true;
            }
            _lang.country = _lang.culture.split('-')[1];
        });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        called when user click language button
    */
    selectLanguage(_selected: Language): void {
        if (_selected.code !== this.translateService.currentLang) {
            this.loading = true;
            this.spinner.show();
            this.languages.forEach(_lang => {
                _lang.selected = false;
            });
            _selected.selected = true;
            this.translateService
                .use(_selected.code)
                .pipe(
                    finalize(() => {
                        this.zone.run(() => {
                            this.loading = false;
                            this.spinner.hide();
                        });
                    }),
                )
                .subscribe(() => {
                    this.requestService.setLang(_selected.code);
                    this.cacheService.setLang(_selected.code);
                    moment.locale(_selected.code);
                    this.utilService.languages = [];
                    this.utilService.countries = [];
                    this.eventService.sendEvent('language_updated', {
                        code: _selected.code,
                    });
                    this.dialogRef.close({
                        refresh: true,
                    });
                });
        }
    }
}
