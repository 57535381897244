import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injector, Injectable } from '@angular/core';

import { RequestService } from './request.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    oldError: any;
    constructor(private injector: Injector) {}

    handleError(error: any): void {
        const requestService = this.injector.get(RequestService);
        const location = this.injector.get(LocationStrategy);
        const url =
            location instanceof PathLocationStrategy ? location.path() : '';
        if (error instanceof HttpErrorResponse) {
            // server error
        } else {
            if (!this.oldError || this.oldError.message != error.message) {
                this.oldError = error;
                if (error.stack) {
                    if (error.message != 'google is not defined') {
                        requestService
                            .postRequest('/error', {
                                url: url,
                                name: error.name,
                                message: error.message,
                                file: error.fileName ? error.fileName : '', // only works on firefox
                                line: error.lineNumber ? error.lineNumber : '', // only works on firefox
                                stack: error.stack
                                    ? error.stack.toString()
                                    : '',
                            })
                            .subscribe();
                    }
                }
            }
        }
        console.log(error);
    }
}
