import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FCM } from '@ionic-native/fcm/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import {
    AuthService,
    CacheService,
    ConfigService,
    JobService,
    UpdateService,
} from './services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [],
})
export class AppComponent implements OnInit {
    notificationId = 0;
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private cache: CacheService,
        private auth: AuthService,
        private jobService: JobService,
        private update: UpdateService,
        private router: Router,
        private fcm: FCM,
        private localNotification: LocalNotifications,
    ) {
        this.initializeApp();
    }

    ngOnInit(): void {
        const _lang = this.cache.getLang();
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang(
            ConfigService.settings.i18n.defaultLanguage.code,
        );
        this.translate.use(_lang);
        moment.locale(_lang);
    }

    initializeApp(): void {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            // get device token
            this.fcm.getToken().then(
                token => {
                    console.log('fcm token...');
                    console.log(token);
                    this.auth.setDeviceToken(token);
                },
                () => {
                    console.log('get token error...');
                },
            );
            // save token when refresh
            this.fcm.onTokenRefresh().subscribe(token => {
                this.auth.setDeviceToken(token);
            });
            // called when receive notification
            this.fcm.onNotification().subscribe(data => {
                if (data.wasTapped) {
                    //Notification was received on device tray and tapped by the user.
                    console.log('Tapped: ' + JSON.stringify(data));
                    this.handleNotification(data);
                } else {
                    //Notification was received in foreground. Maybe the user needs to be notified.
                    console.log('Not tapped: ' + JSON.stringify(data));
                    this.showLocalNotification(data.title, data.body, data);
                }
            });
            // called when notification is tapped
            this.localNotification.on('click').subscribe(_payload => {
                this.handleNotification(_payload.data);
            });
        });
    }
    /*
        show local notification
    */
    showLocalNotification(title: string, text: string, data: any): void {
        if (this.auth.getToken()) {
            this.notificationId++;
            this.localNotification.schedule({
                id: this.notificationId,
                title: title,
                text: text,
                data: data,
            });
        }
    }
    /*
        handle notification
    */
    handleNotification(_data: any): void {
        if (
            _data.type == 'appointment_created' ||
            _data.type == 'appointment_moved' ||
            _data.type == 'appointment_cancelled'
        ) {
            if (this.auth.getToken()) {
                this.jobService.getItemById(_data.job_id).subscribe(() => {
                    this.router.navigate([
                        '/',
                        'main',
                        'jobs',
                        'detail',
                        _data.job_id,
                    ]);
                });
            }
        }
    }
}
