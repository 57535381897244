// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';

// service
import { Contact } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class ContactService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all contacts
    */
    getAll(): Observable<Contact[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/contact/all?' + params.toString(), {})
            .pipe(map(res => res.contacts));
    }
    /*
        get contact list
    */
    getList(): Observable<Contact[]> {
        return this.storageService.getDataList('contacts').pipe(
            switchMap((_contacts: Contact[]) => {
                return this.storageService.getDataList('companies').pipe(
                    tap(_companies => {
                        _contacts.forEach(_contact => {
                            if (_contact.company_id != null) {
                                _contact.company = _companies.find(
                                    _item => _item.id == _contact.company_id,
                                );
                            }
                        });
                    }),
                    map(() => _contacts),
                );
            }),
        );
    }
    /*
        get contact by id
    */
    getItemById(_id: any): Observable<Contact> {
        return this.storageService.getDataItem('contacts', _id).pipe(
            switchMap((_contact: Contact) => {
                return this.storageService
                    .getDataItem('companies', _contact.company_id)
                    .pipe(
                        tap(_company => {
                            _contact.company = _company;
                        }),
                        map(() => _contact),
                    );
            }),
        );
    }
}
