import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Contact } from '../../app.interface';

@Component({
    selector: 'app-contacts-dialog',
    templateUrl: './contacts.dialog.html',
    styleUrls: [],
})
export class ContactsDialog implements OnInit, OnDestroy {
    loading = false;
    contacts: Contact[] = [];
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<ContactsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.contacts = this.data.contacts;

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
