import {
    OnInit,
    ElementRef,
    OnDestroy,
    Component,
    HostListener,
    Output,
    EventEmitter,
    HostBinding,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: [],
})
export class SearchComponent implements OnInit, OnDestroy {
    @Output() onActive = new EventEmitter<any>();
    @Output() onSearch = new EventEmitter<string>();
    active = false;
    searchCtrl: FormControl;
    protected _onDestroy = new Subject<void>();
    constructor(private _elementRef: ElementRef) {}

    @HostBinding('class')
    get activeClass(): string {
        return this.active ? 'active' : '';
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement): void {
        const clickedInside = this._elementRef.nativeElement.contains(
            targetElement,
        );
        if (!clickedInside) {
            this.active = false;
        } else {
            this.active = true;
        }
        this.onActive.emit({ active: this.active });
    }

    ngOnInit(): void {
        this.searchCtrl = new FormControl('');

        this.searchCtrl.valueChanges
            .pipe(debounceTime(400), takeUntil(this._onDestroy))
            .subscribe(_value => {
                if (this.active == true) {
                    this.onSearch.emit(_value);
                } else {
                    this.searchCtrl.setValue('', { emitEvent: false });
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        called when user click 'back_arrow' button
    */
    close(_event: Event): void {
        this.active = false;
        this.onActive.emit({ active: this.active });
        _event.stopPropagation();
    }
    /*
        called when user click 'clear' button
    */
    clear(_event: Event): void {
        this.searchCtrl.setValue('', { emitEvent: false });
        _event.stopPropagation();
    }
}
