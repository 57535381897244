// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

// service
import { Appointment } from '../app.interface';

import { StorageService } from './storage.service';

@Injectable()
export class AppointmentService {
    constructor(private storageService: StorageService) {}
    /*
        create appointment
    */
    createItem(_apiData: Appointment, _fullData: Appointment): Observable<any> {
        return this.storageService
            .createItem('appointments', _apiData, _fullData)
            .pipe(
                switchMap(_appointment => {
                    return this.storageService
                        .getDataItem('jobs', _apiData.job_id)
                        .pipe(
                            switchMap(_job => {
                                _job.appointments.push(_appointment);
                                _job.state = 4;
                                return this.storageService
                                    .updateDataItem(
                                        'jobs',
                                        {
                                            id: _job.id,
                                            state: _job.state,
                                        },
                                        _job,
                                        false,
                                    )
                                    .pipe(map(() => _appointment.id));
                            }),
                        );
                }),
            );
    }
}
