import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IonicStorageModule } from '@ionic/storage';

import { AppointmentService } from './appointment.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { ChecklistStepService } from './checklist-step.service';
import { ChecklistService } from './checklist.service';
import { CompanyService } from './company.service';
import { ConfigService } from './config.service';
import { ConnectionService } from './connection.service';
import { ContactService } from './contact.service';
import { DocumentService } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { EventService } from './event.service';
import { GuardService } from './guard.service';
import { InterceptorService } from './interceptor.service';
import { ItemService } from './item.service';
import { JobService } from './job.service';
import { LocationService } from './location.service';
import { MenuService } from './menu.service';
import { ProductService } from './product.service';
import { RequestService } from './request.service';
import { RoleService } from './role.service';
import { ScriptService } from './script.service';
import { ServiceobjectService } from './serviceobject.service';
import { StorageService } from './storage.service';
import { SyncDataService } from './sync-data.service';
import { TagService } from './tag.service';
import { TimeEventService } from './time-event.service';
import { UnitService } from './unit.service';
import { UpdateService } from './update.service';
import { UserService } from './user.service';
import { UtilService } from './util.service';

@NgModule({
    imports: [MatSnackBarModule, IonicStorageModule],
    declarations: [],
    providers: [
        AppointmentService,
        AuthService,
        CacheService,
        ChecklistStepService,
        ChecklistService,
        CompanyService,
        ConfigService,
        ConnectionService,
        ContactService,
        DocumentService,
        ErrorHandlerService,
        EventService,
        GuardService,
        InterceptorService,
        ItemService,
        JobService,
        LocationService,
        MenuService,
        ProductService,
        RequestService,
        RoleService,
        ServiceobjectService,
        ScriptService,
        StorageService,
        SyncDataService,
        TagService,
        TimeEventService,
        UnitService,
        UpdateService,
        UserService,
        UtilService,
    ],
})
export class ServicesModule {}
