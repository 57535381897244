import { HttpClient } from '@angular/common/http';
import { Injector, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConfigService {
    static settings: any;
    constructor(private injector: Injector) {}

    load(): Promise<any> {
        const jsonFile = 'assets/config.json';
        const http = this.injector.get(HttpClient);
        return http
            .get(jsonFile)
            .pipe(
                tap(response => {
                    ConfigService.settings = response;
                    console.log('loaded config...');
                }),
            )
            .toPromise();
    }
}
