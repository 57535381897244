import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-rename-dialog',
    templateUrl: './rename.dialog.html',
    styleUrls: [],
})
export class RenameDialog implements OnInit, OnDestroy {
    loading = false;
    form: FormGroup;
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<RenameDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [this.data.name, Validators.compose([Validators.required])],
        });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.dialogRef.close({
                refresh: true,
                name: this.form.value.name,
            });
        }
    }
}
