import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
/*
    Location Service
*/
@Injectable()
export class LocationService {
    getPosition(): Observable<any> {
        return new Observable(observer => {
            // Get the next and error callbacks. These will be passed in when
            // the consumer subscribes.
            const _success: PositionCallback = (pos: any) => {
                observer.next(pos);
            };
            const _error: PositionErrorCallback | any = () => {
                observer.next({ coords: ConfigService.settings.location });
            };
            if (!navigator.geolocation) {
                observer.next({ coords: ConfigService.settings.location });
            } else {
                navigator.geolocation.getCurrentPosition(_success, _error);
            }
        });
    }
    /*
        calculate distance
        unit - 'M' is statute miles (default), 'K' is kilometers, 'N' is nautical miles
    */
    calculateDistance(
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number,
        unit?: string,
    ): number {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == 'K') {
            dist = dist * 1.609344;
        }
        if (unit == 'N') {
            dist = dist * 0.8684;
        }
        return dist;
    }
}
