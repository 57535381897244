import { registerLocaleData } from '@angular/common';
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
    NgModule,
    APP_INITIALIZER,
    ErrorHandler,
    CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    RouterModule,
    RouteReuseStrategy,
    PreloadAllModules,
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    PerfectScrollbarConfigInterface,
    PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { LayoutsModule } from './layouts/layouts.module';
import { AuthModule } from './pages/auth/auth.module';
import { ErrorComponent } from './pages/error/error.component';
import { PipesModule } from './pipes/pipes.module';
import {
    ConfigService,
    InterceptorService,
    ErrorHandlerService,
} from './services';
import { ServicesModule } from './services/services.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeDe);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true,
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(configService: ConfigService): any {
    return () => configService.load();
}

@NgModule({
    declarations: [AppComponent, ErrorComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        ComponentsModule,
        PipesModule,
        LayoutsModule,
        DialogsModule,
        AuthModule,
        ServicesModule,
        RouterModule.forRoot(AppRoutes, {
            preloadingStrategy: PreloadAllModules,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        },
        StatusBar,
        SplashScreen,
        BarcodeScanner,
        SocialSharing,
        FCM,
        LocalNotifications,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent],
    entryComponents: [],
})
export class AppModule {}
