import { OnInit, OnDestroy, NgZone, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { JobItem, Item, Unit, Job } from '../../app.interface';
import {
    ItemService,
    UtilService,
    UnitService,
    JobService,
    EventService,
    CacheService,
} from '../../services';

@Component({
    selector: 'app-job-item-dialog',
    templateUrl: './job-item.dialog.html',
    styleUrls: [],
})
export class JobItemDialog implements OnInit, OnDestroy {
    loading = false;
    job: Job;
    jobItem: JobItem;
    form: FormGroup;
    units: Unit[] = [];
    allItems: Item[] = [];
    filteredItems: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1);
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        private zone: NgZone,
        private itemService: ItemService,
        private utilService: UtilService,
        private unitService: UnitService,
        private jobService: JobService,
        private eventService: EventService,
        private barcodeScanner: BarcodeScanner,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<JobItemDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.form = this.fb.group({
            name: [
                this.jobItem?.name ? { name: this.jobItem.name } : null,
                Validators.compose([Validators.required]),
            ],
            price: [
                this.jobItem?.price ? this.jobItem.price : null,
                Validators.compose([Validators.required]),
            ],
            quantity: [
                this.jobItem?.quantity ? this.jobItem.quantity : null,
                Validators.compose([Validators.required]),
            ],
            unit_id: [
                this.jobItem?.unit_id ? this.jobItem.unit_id : null,
                Validators.compose([Validators.required]),
            ],
        });

        // listen for search field value changes
        this.form.controls['name'].valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(_value => {
                this.utilService.filterList(
                    this.filteredItems,
                    this.allItems,
                    _value,
                    [],
                );
            });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        refresh data
    */
    refreshData(): void {
        this.loading = true;
        this.spinner.show();
        const _syncIds = this.cacheService.getDataList('sync_ids');
        if (this.data.job) {
            this.data.job.id = _syncIds[this.data.job.id]
                ? _syncIds[this.data.job.id]
                : this.data.job.id;
            this.job = this.data.job;
        }
        if (this.data.jobItem) {
            this.data.jobItem.id = _syncIds[this.data.jobItem.id]
                ? _syncIds[this.data.jobItem.id]
                : this.data.jobItem.id;
            this.jobItem = this.data.jobItem;
        }

        this.itemService
            .getList()
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.spinner.hide();
                }),
            )
            .subscribe((res: Item[]) => {
                this.allItems = res;
                this.utilService.filterList(
                    this.filteredItems,
                    this.allItems,
                    null,
                    [],
                );
            });

        this.unitService.getList().subscribe(res => {
            this.units = res;
        });
    }

    /*
        called when user select item
    */
    selectItem(_event: any): void {
        const _item: Item = _event.option.value;
        this.form.patchValue(
            {
                price: _item.price,
                quantity: 1,
                unit_id: _item.unit_id,
            },
            { emitEvent: false },
        );
    }

    displayNameFn(item?: Item): string | undefined {
        return item ? item.name : undefined;
    }

    /*
        called when user click 'remove' button
    */
    remove(): void {
        this.loading = true;
        this.spinner.show();
        this.jobService
            .deleteMaterial(this.jobItem)
            .pipe(
                finalize(() => {
                    this.zone.run(() => {
                        this.loading = false;
                        this.spinner.hide();
                    });
                }),
            )
            .subscribe(() => {
                this.dialogRef.close({
                    refresh: true,
                });
            });
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.loading = true;
            this.spinner.show();
            const formData = this.form.value;
            const _jobItem: JobItem = {
                name: formData.name.name ? formData.name.name : formData.name,
                price: formData.price,
                quantity: formData.quantity,
                unit_id: formData.unit_id,
            };
            if (this.jobItem?.id) {
                _jobItem.id = this.jobItem.id;
                _jobItem.job_id = this.jobItem.job_id;
                _jobItem.description = this.jobItem.description;
                _jobItem.sort = this.jobItem.sort;
                _jobItem.checklist_step_id = this.jobItem.checklist_step_id;
                _jobItem.type = this.jobItem.type;
                const _fullJobItem = JSON.parse(JSON.stringify(_jobItem));
                _fullJobItem.unit = this.units.find(
                    _item => _item.id == _fullJobItem.unit_id,
                );
                this.jobService
                    .updateMaterial(_jobItem, _fullJobItem)
                    .pipe(
                        finalize(() => {
                            this.zone.run(() => {
                                this.loading = false;
                                this.spinner.hide();
                            });
                        }),
                    )
                    .subscribe(() => {
                        this.dialogRef.close({
                            refresh: true,
                        });
                    });
            } else {
                _jobItem.job_id = this.job.id;
                if (this.job.items.length > 0) {
                    _jobItem.sort =
                        Math.max(
                            ...this.job.items.map(function (_item) {
                                return _item.sort;
                            }),
                        ) + 1;
                } else {
                    _jobItem.sort = 0;
                }
                _jobItem.type = 1;
                const _fullJobItem = JSON.parse(JSON.stringify(_jobItem));
                _fullJobItem.unit = this.units.find(
                    _item => _item.id == _fullJobItem.unit_id,
                );
                this.jobService
                    .createMaterial(_jobItem, _fullJobItem)
                    .pipe(
                        finalize(() => {
                            this.zone.run(() => {
                                this.loading = false;
                                this.spinner.hide();
                            });
                        }),
                    )
                    .subscribe(() => {
                        this.dialogRef.close({
                            refresh: true,
                        });
                    });
            }
        }
    }
    /*
        called when user click 'scan' button
    */
    startScanning(): void {
        this.barcodeScanner
            .scan()
            .then(barcodeData => {
                if (barcodeData.text) {
                    const _codeItem = this.allItems.find(_item => {
                        return (
                            _item.gtin
                                .toLowerCase()
                                .indexOf(barcodeData.text.toLowerCase()) > -1
                        );
                    });
                    if (_codeItem) {
                        this.form.patchValue(
                            {
                                name: _codeItem,
                                price: _codeItem.price,
                                quantity: 1,
                                unit_id: _codeItem.unit_id,
                            },
                            { emitEvent: false },
                        );
                    } else {
                        this.eventService.sendEvent('open_alert_dialog', {
                            message: 'AlertMessage.NoBarcodeItem',
                            callback: null,
                        });
                    }
                }
            })
            .catch(err => {
                console.log('Error', err);
            });
    }
    /*
        cancel
    */
    cancel(): void {
        this.dialogRef.close();
    }
}
