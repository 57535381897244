import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';

import { ConfigService } from './config.service';

@Injectable()
export class UpdateService {
    constructor(
        private swUpdate: SwUpdate,
        private snackbar: MatSnackBar,
        private translate: TranslateService,
    ) {
        if (swUpdate.isEnabled) {
            // check for update per hour
            interval(
                ConfigService.settings.checkingUpdateInterval * 60 * 60 * 1000,
            ).subscribe(() =>
                swUpdate
                    .checkForUpdate()
                    .then(() => console.log('checking for updates')),
            );
        }

        this.swUpdate.available.subscribe(() => {
            console.log('update available...');
            let snack = null;

            this.translate
                .get(['Label.UpdateAvailable', 'Button.Reload'])
                .subscribe((res: any) => {
                    snack = this.snackbar.open(
                        res['Label.UpdateAvailable'],
                        res['Button.Reload'],
                    );
                });

            snack.onAction().subscribe(() => {
                window.location.reload();
            });
        });
    }
}
