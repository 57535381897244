import { OnDestroy, OnInit, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize, debounceTime } from 'rxjs/operators';

import { Product } from '../../app.interface';
import { ProductService, EventService } from '../../services';

@Component({
    selector: 'app-product-select-dialog',
    templateUrl: './product-select.dialog.html',
    styleUrls: [],
})
export class ProductSelectDialog implements OnInit, OnDestroy {
    loading = false;
    products: Product[] = [];
    allProducts: Product[] = [];
    searchCtrl: FormControl = new FormControl('');
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private productService: ProductService,
        private eventService: EventService,
        public dialogRef: MatDialogRef<ProductSelectDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.searchCtrl.valueChanges
            .pipe(debounceTime(400), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterProducts();
            });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        refresh data
    */
    refreshData(): void {
        this.loading = true;
        this.spinner.show();
        this.productService
            .getList()
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.spinner.hide();
                }),
            )
            .subscribe(_res => {
                this.allProducts = _res.filter(
                    _item => _item.serviceobject_id == null,
                );
                this.filterProducts();
            });
    }
    /*
        filter products
    */
    filterProducts(): void {
        let _products = this.allProducts;
        if (this.searchCtrl.value != '') {
            const _search = this.searchCtrl.value.toLowerCase();
            _products = _products.filter(_product => {
                return (
                    _product.name.toLowerCase().indexOf(_search) > -1 ||
                    _product.id.toString().indexOf(_search) > -1
                );
            });
        }
        this.products = [..._products];
    }
    /*
        called when user click product
    */
    selectProduct(_product: Product): void {
        this.dialogRef.close({
            refresh: true,
            product: _product,
        });
    }
}
