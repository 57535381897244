import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

@Injectable()
export class ScriptService {
    private scripts: any = {};

    constructor() {
        const _scripts = [
            {
                name: 'googlemap',
                src:
                    'https://maps.googleapis.com/maps/api/js?key=' +
                    ConfigService.settings.googleApiKey +
                    '&libraries=places&language=en',
            },
        ];
        _scripts.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                loading: false,
                src: script.src,
                promise: null,
            };
        });
    }

    // load a single or multiple scripts
    load(...scripts: string[]): Promise<any> {
        const promises: any[] = [];
        // push the returned promise of each loadScript call
        scripts.forEach(script => promises.push(this.loadScript(script)));
        // return promise.all that resolves when all promises are resolved
        return Promise.all(promises);
    }

    // load the script
    loadScript(name: string): Promise<any> {
        this.scripts[name].promise = new Promise(resolve => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({
                    script: name,
                    loaded: true,
                    status: 'Already Loaded',
                });
            } else {
                if (this.scripts[name].loading) {
                    this.scripts[name].promise.then(() => {
                        resolve({
                            script: name,
                            loaded: true,
                            status: 'Already Loaded',
                        });
                    });
                } else {
                    // load script
                    const script: any = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = this.scripts[name].src;
                    this.scripts[name].loading = true;
                    // cross browser handling of onLoaded event
                    if (script.readyState) {
                        // IE
                        script.onreadystatechange = () => {
                            if (
                                script.readyState === 'loaded' ||
                                script.readyState === 'complete'
                            ) {
                                script.onreadystatechange = null;
                                this.scripts[name].loaded = true;
                                this.scripts[name].loading = false;
                                resolve({
                                    script: name,
                                    loaded: true,
                                    status: 'Loaded',
                                });
                            }
                        };
                    } else {
                        // Others
                        script.onload = () => {
                            this.scripts[name].loaded = true;
                            this.scripts[name].loading = false;
                            resolve({
                                script: name,
                                loaded: true,
                                status: 'Loaded',
                            });
                        };
                    }
                    script.onerror = () =>
                        resolve({
                            script: name,
                            loaded: false,
                            status: 'Loaded',
                        });
                    // finally append the script tag in the DOM
                    document
                        .getElementsByTagName('head')[0]
                        .appendChild(script);
                }
            }
        });
        return this.scripts[name].promise;
    }
}
