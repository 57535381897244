import { NgModule } from '@angular/core';

import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';

import { AppBlankComponent } from './blank/blank.component';
import { FullComponent } from './full/full.component';
import { AppSidebarComponent } from './full/sidebar/sidebar.component';

@NgModule({
    imports: [SharedModule, ComponentsModule, PipesModule],
    declarations: [FullComponent, AppBlankComponent, AppSidebarComponent],
    exports: [],
    providers: [],
})
export class LayoutsModule {}
