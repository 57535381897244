// angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// libs
import { Observable } from 'rxjs';

import { CacheService } from './cache.service';
import { ConfigService } from './config.service';

@Injectable()
export class RequestService {
    apiEndpoint = '';
    lang = '';
    constructor(private http: HttpClient, private cacheService: CacheService) {
        this.apiEndpoint = ConfigService.settings.apiEndpoint;
        this.lang = this.cacheService.getLang();
    }

    setLang(lang: string): void {
        this.lang = lang;
    }

    getRequest(endpoint: string): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        return this.http.get(this.apiEndpoint + endpoint, { headers });
    }

    postRequest(endpoint: string, params: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        params.lang = this.lang;
        return this.http.post(this.apiEndpoint + endpoint, params, { headers });
    }

    putRequest(endpoint: string, params: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        params.lang = this.lang;
        return this.http.put(this.apiEndpoint + endpoint, params, { headers });
    }

    postFormRequest(endpoint: string, params: any): Observable<any> {
        return this.http.post(this.apiEndpoint + endpoint, params);
    }

    putFormRequest(endpoint: string, params: any): Observable<any> {
        return this.http.put(this.apiEndpoint + endpoint, params);
    }

    getParamRequest(endpoint: string, params: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        params.lang = this.lang;
        return this.http.get(this.apiEndpoint + endpoint, { headers, params });
    }

    deleteParamRequest(endpoint: string, params: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        params.lang = this.lang;
        return this.http.delete(this.apiEndpoint + endpoint, {
            headers,
            params,
        });
    }
}
