import {
    OnInit,
    OnDestroy,
    NgZone,
    Component,
    Inject,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    PerfectScrollbarConfigInterface,
    PerfectScrollbarDirective,
} from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { Job, JobMessage, User } from '../../app.interface';
import {
    AuthService,
    JobService,
    EventService,
    CacheService,
} from '../../services';

@Component({
    selector: 'app-job-messages-dialog',
    templateUrl: './job-messages.dialog.html',
    styleUrls: [],
})
export class JobMessagesDialog implements OnInit, OnDestroy {
    @ViewChild(PerfectScrollbarDirective, { static: true })
    directiveRef?: PerfectScrollbarDirective;
    loading = false;
    job: Job;
    messages: JobMessage[];
    form: FormGroup;
    userInfo: User;
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        private zone: NgZone,
        private authService: AuthService,
        private jobService: JobService,
        private eventService: EventService,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<JobMessagesDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.userInfo = this.authService.getUserInfo();
        this.form = this.fb.group({
            message: [null, Validators.compose([Validators.required])],
        });
        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        get messages
    */
    refreshData(): void {
        this.loading = true;
        this.spinner.show();
        const _syncIds = this.cacheService.getDataList('sync_ids');
        this.data.job.id = _syncIds[this.data.job.id]
            ? _syncIds[this.data.job.id]
            : this.data.job.id;
        this.job = this.data.job;
        this.jobService
            .getItemById(this.job.id)
            .pipe(
                finalize(() => {
                    this.zone.run(() => {
                        this.loading = false;
                        this.spinner.hide();
                    });
                }),
            )
            .subscribe((_job: Job) => {
                this.job = _job;
                this.messages = this.job.messages;
                setTimeout(() => {
                    this.directiveRef.scrollToBottom();
                }, 500);
            });
    }
    /*
        called when user click 'send' button
    */
    onSubmit(): void {
        if (this.form.valid) {
            this.loading = true;
            this.spinner.show();
            const messageData: JobMessage = {
                user_id: this.userInfo.id,
                job_id: this.job.id,
                message: this.form.value.message,
            };
            this.jobService
                .createMessage(messageData, messageData)
                .pipe(
                    finalize(() => {
                        this.zone.run(() => {
                            this.loading = false;
                            this.spinner.hide();
                        });
                    }),
                )
                .subscribe(() => {
                    this.form.reset();
                    this.refreshData();
                });
        }
    }
}
