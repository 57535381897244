import { OnInit, Component } from '@angular/core';

import { User } from '../../app.interface';
import { AuthService, EventService, MenuService } from '../../services';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: [],
})
export class HeaderComponent implements OnInit {
    menuItems: any;
    userInfo: User;

    constructor(
        private auth: AuthService,
        private event: EventService,
        private menu: MenuService,
    ) {}

    ngOnInit(): void {
        this.menuItems = this.menu.getMenuItems();
        this.userInfo = this.auth.getUserInfo();
    }

    onMenuClick(_action: string): void {
        this.event.sendEvent(_action, {
            ref: null,
            callback: null,
        });
    }
}
