import { Injectable } from '@angular/core';
import { ConnectionService as Connection } from 'ng-connection-service';

import { EventService } from './event.service';
/*
    Connection Service
*/
@Injectable()
export class ConnectionService {
    private isConnected = false;
    constructor(private connection: Connection, private event: EventService) {
        this.isConnected = navigator.onLine;
        this.connection.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            this.event.sendEvent('connection_updated', {
                connected: this.isConnected,
            });
        });
    }
    /*
        return connected status
    */
    getConnected(): boolean {
        return this.isConnected;
    }
}
