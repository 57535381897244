import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import moment from 'moment';
import { Observable, from } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { EventService } from './event.service';
/*
    Location Service
*/
@Injectable()
export class StorageService {
    constructor(private storage: Storage, private eventService: EventService) {}
    /*
        clear data
    */
    clear(): Observable<any> {
        return from(this.storage.clear());
    }
    /*
        get datalist by key from local database
    */
    getDataList(_key: string): Observable<any[]> {
        return from(this.storage.get('app_data_' + _key)).pipe(
            map(res => JSON.parse(res) || []),
        );
    }
    /*
        set datalist by key to local database
    */
    setDataList(_key: string, _data: any): Observable<any> {
        return from(
            this.storage.set('app_data_' + _key, JSON.stringify(_data)),
        ).pipe(
            tap(() => {
                this.eventService.sendEvent('data_updated', { key: _key });
            }),
            map(() => 'success'),
        );
    }
    /*
        clear datalist by key
    */
    clearDataList(_key: string): Observable<any> {
        return from(this.storage.remove('app_data_' + _key));
    }
    /*
        get data item by id and key
    */
    getDataItem(_key: string, _id: any): Observable<any> {
        return this.getDataList(_key).pipe(
            map(res => {
                const _findItem = res.find(_item => _item.id == _id);
                return _findItem;
            }),
        );
    }
    /*
        add data item by key
    */
    createDataItem(
        _key: string,
        _apiData: any,
        _fullData: any,
        _action: boolean,
    ): Observable<any> {
        return this.getDataList(_key).pipe(
            switchMap(res => {
                _apiData['id'] = _fullData['id'] = uuid();
                _apiData['created_at'] = _fullData[
                    'created_at'
                ] = moment().utc().format('YYYY-MM-DD HH:mm:ss');
                res.push(_fullData);
                if (_action) {
                    // should save to action
                    this.createAction(_key, 'create', _apiData).subscribe();
                }
                return this.setDataList(_key, res).pipe(map(() => _fullData));
            }),
        );
    }
    /*
        update data item
    */
    updateDataItem(
        _key: string,
        _apiData: any,
        _fullData: any,
        _action: boolean,
    ): Observable<any> {
        return this.getDataList(_key).pipe(
            switchMap(res => {
                _apiData['updated_at'] = _fullData[
                    'updated_at'
                ] = moment().utc().format('YYYY-MM-DD HH:mm:ss');
                const _findItemIndex = res.findIndex(
                    _item => _item.id == _fullData.id,
                );
                if (_findItemIndex > -1) {
                    res[_findItemIndex] = _fullData;
                }
                if (_action) {
                    // should save to action
                    this.createAction(_key, 'update', _apiData).subscribe();
                }
                return this.setDataList(_key, res).pipe(map(() => _fullData));
            }),
        );
    }
    /*
        remove data item by key
    */
    deleteDataItem(_key: string, _id: any, _action: boolean): Observable<any> {
        return this.getDataList(_key).pipe(
            switchMap(res => {
                const _findItemIndex = res.findIndex(_item => _item.id == _id);
                if (_findItemIndex > -1) {
                    res.splice(_findItemIndex, 1);
                    if (_action) {
                        this.createAction(_key, 'delete', {
                            id: _id,
                            deleted_at: moment()
                                .utc()
                                .format('YYYY-MM-DD HH:mm:ss'),
                        }).subscribe();
                    }
                }
                return this.setDataList(_key, res).pipe(map(() => _id));
            }),
        );
    }
    /*
        add item by key
    */
    createItem(_key: string, _apiData: any, _fullData: any): Observable<any> {
        _apiData['id'] = _fullData['id'] = uuid();
        _apiData['created_at'] = _fullData[
            'created_at'
        ] = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        return this.createAction(_key, 'create', _apiData).pipe(
            map(() => _fullData),
        );
    }
    /*
        update data item
    */
    updateItem(_key: string, _apiData: any, _fullData: any): Observable<any> {
        _apiData['updated_at'] = _fullData[
            'updated_at'
        ] = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        return this.createAction(_key, 'update', _apiData).pipe(
            map(() => _fullData),
        );
    }
    /*
        remove item by key which is not exist on local database
    */
    deleteItem(_key: string, _id: any): Observable<any> {
        return this.createAction(_key, 'delete', {
            id: _id,
            deleted_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        });
    }
    /*
        create action
    */
    createAction(_key: string, _type: string, _data: any): Observable<any> {
        return this.getDataList('actions').pipe(
            switchMap(res => {
                const _action = {
                    name: _key,
                    type: _type,
                    data: _data,
                };
                res.push(_action);
                return this.setDataList('actions', res);
            }),
        );
    }
}
