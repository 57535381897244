// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// service
import { Tag } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class TagService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all tags
    */
    getAll(): Observable<Tag[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/tag/all?' + params.toString(), {})
            .pipe(map(res => res.tags));
    }
    /*
        get tag list
    */
    getList(_type?: number): Observable<Tag[]> {
        return this.storageService.getDataList('tags').pipe(
            map(res => {
                if (_type) {
                    return res.filter(_item => _item.type == _type);
                } else {
                    return res;
                }
            }),
        );
    }
    /*
        get tag by id
    */
    getItemById(_id: any): Observable<Tag> {
        return this.storageService.getDataItem('tags', _id);
    }
}
