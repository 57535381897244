import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Product } from '../../app.interface';

@Component({
    selector: 'app-product-state-dialog',
    templateUrl: './product-state.dialog.html',
    styleUrls: [],
})
export class ProductStateDialog implements OnInit, OnDestroy {
    loading = false;
    product: Product;
    states = [];
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<ProductStateDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.product = this.data.product;

        const _states = [
            {
                value: 1,
                text: 'Label.EnableProduct',
                icon: 'fa-check-square-o',
            },
            { value: 2, text: 'Label.DisableProduct', icon: 'fa-ban' },
            { value: 3, text: 'Label.RemoveProduct', icon: 'fa-trash-o' },
        ];

        this.states = _states.filter(_state => {
            if (this.product.enabled) {
                return _state.value != 1;
            } else {
                return _state.value != 2;
            }
        });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        called when user click state item
    */
    selectState(_state: string): void {
        this.dialogRef.close({
            refresh: true,
            state: _state,
        });
    }
}
