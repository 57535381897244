import { OnDestroy, OnInit, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize, debounceTime } from 'rxjs/operators';

import { Checklist, Job } from '../../app.interface';
import { ChecklistService, EventService, CacheService } from '../../services';

@Component({
    selector: 'app-checklist-select-dialog',
    templateUrl: './checklist-select.dialog.html',
    styleUrls: [],
})
export class ChecklistSelectDialog implements OnInit, OnDestroy {
    loading = false;
    job: Job;
    checklists: Checklist[] = [];
    allChecklists: Checklist[] = [];
    searchCtrl: FormControl = new FormControl('');
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private checklistService: ChecklistService,
        private eventService: EventService,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<ChecklistSelectDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();
        this.searchCtrl.valueChanges
            .pipe(debounceTime(400), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterChecklists();
            });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        refresh data
    */
    refreshData(): void {
        this.loading = true;
        this.spinner.show();
        const _syncIds = this.cacheService.getDataList('sync_ids');
        this.data.job.id = _syncIds[this.data.job.id]
            ? _syncIds[this.data.job.id]
            : this.data.job.id;
        this.job = this.data.job;
        this.checklistService
            .getList()
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.spinner.hide();
                }),
            )
            .subscribe(_res => {
                this.allChecklists = _res.filter(_checklist => {
                    const _duplicated = this.job.checklists.find(_ditem => {
                        return _ditem.id == _checklist.id;
                    });
                    return !_duplicated;
                });
                this.filterChecklists();
            });
    }
    /*
        filter checklists
    */
    filterChecklists(): void {
        let _checklists = this.allChecklists;
        if (this.searchCtrl.value != '') {
            const _search = this.searchCtrl.value.toLowerCase();
            _checklists = _checklists.filter(_checklist => {
                return (
                    _checklist.name.toLowerCase().indexOf(_search) > -1 ||
                    _checklist.id.toString().indexOf(_search) > -1
                );
            });
        }
        this.checklists = [..._checklists];
    }
    /*
        called when user click checklist
    */
    selectChecklist(_checklist: Checklist): void {
        this.dialogRef.close({
            refresh: true,
            checklist: _checklist,
        });
    }
}
