// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// service
import { Item } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class ItemService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all item
    */
    getAll(): Observable<Item[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/item/all?' + params.toString(), {})
            .pipe(map(res => res.items));
    }
    /*
        get item list
    */
    getList(): Observable<Item[]> {
        return this.storageService.getDataList('items').pipe(
            tap(res => {
                res.forEach((_item: Item) => {
                    if (_item.image_file == null) {
                        _item.image_file = 'assets/images/document.png';
                    }
                });
            }),
        );
    }
    /*
        get item by id
    */
    getItemById(_id: any): Observable<Item> {
        return this.storageService.getDataItem('items', _id).pipe(
            tap(res => {
                if (res.image_file == null) {
                    res.image_file = 'assets/images/document.png';
                }
            }),
        );
    }
}
