import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm.dialog.html',
    styleUrls: [],
})
export class ConfirmDialog implements OnInit, OnDestroy {
    protected _onDestroy = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    confirm(): void {
        this.dialogRef.close({
            confirm: true,
        });
    }

    ngOnInit(): void {
        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
