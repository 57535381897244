// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';

// service
import { Product } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class ProductService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all products
    */
    getAll(): Observable<Product[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/product/all?' + params.toString(), {})
            .pipe(map(res => res.products));
    }
    /*
        get product list
    */
    getList(): Observable<Product[]> {
        return this.storageService.getDataList('products');
    }
    /*
        get product by id
    */
    getItemById(_id: any): Observable<Product> {
        return this.storageService.getDataItem('products', _id).pipe(
            switchMap((_product: Product) => {
                return this.storageService.getDataList('checklist_steps').pipe(
                    tap(_checklistSteps => {
                        _product.checklist_steps = _checklistSteps.filter(
                            _item => _item.product_id == _product.id,
                        );
                        _product.checklist_steps.forEach(_step => {
                            if (_step.type == 1) {
                                _step.checklist_step_choices.sort(
                                    (a: any, b: any) =>
                                        a.sort > b.sort
                                            ? 1
                                            : b.sort > a.sort
                                            ? -1
                                            : 0,
                                );
                            }
                        });
                    }),
                    map(() => _product),
                );
            }),
        );
    }
    /*
        delete item
    */
    deleteItem(_product: Product): Observable<any> {
        return this.storageService.deleteDataItem(
            'products',
            _product.id,
            true,
        );
    }
}
