import { Routes } from '@angular/router';

import { AppBlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ErrorComponent } from './pages/error/error.component';
import { GuardService } from './services';

export const AppRoutes: Routes = [
    {
        path: 'main',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: '/main/jobs',
                pathMatch: 'full',
            },
            {
                path: 'contacts',
                loadChildren: () =>
                    import('./pages/contacts/contacts.module').then(
                        m => m.ContactsModule,
                    ),
                canLoad: [GuardService],
            },
            {
                path: 'jobs',
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then(m => m.JobsModule),
                canLoad: [GuardService],
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('./pages/settings/settings.module').then(
                        m => m.SettingsModule,
                    ),
                canLoad: [GuardService],
            },
            {
                path: 'time-tracking',
                loadChildren: () =>
                    import('./pages/time-tracking/time-tracking.module').then(
                        m => m.TimeTrackingModule,
                    ),
                canLoad: [GuardService],
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: '',
                redirectTo: '/main/jobs',
                pathMatch: 'full',
            },
            {
                path: '404',
                component: ErrorComponent,
                data: {
                    animation: 'mater',
                },
            },
            {
                path: 'login',
                component: LoginComponent,
                data: {
                    animation: 'master',
                },
            },
        ],
    },
    {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full',
    },
];
