import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MIME_TYPES } from '../../app.constant';
import { Document } from '../../app.interface';
import { EventService } from '../../services';

@Component({
    selector: 'app-document-preview-dialog',
    templateUrl: './document-preview.dialog.html',
    styleUrls: [],
})
export class DocumentPreviewDialog implements OnInit, OnDestroy {
    loading = false;
    editing = false;
    document: Document;
    config: PerfectScrollbarConfigInterface = {};
    pdfData: any;
    protected _onDestroy = new Subject<void>();
    constructor(
        private eventService: EventService,
        public sanitizer: DomSanitizer,
        private translate: TranslateService,
        private socialSharing: SocialSharing,
        public dialogRef: MatDialogRef<DocumentPreviewDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.document = this.data.document;
        this.document.mimeType =
            MIME_TYPES[this.document.name.split('.').pop()];
        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        called when user click 'share' icon
    */
    shareDocument(): void {
        // this is the complete list of currently supported params you can pass to the plugin (all optional)
        const options = {
            message: this.translate.instant('Label.ShareThis'), // not supported on some apps (Facebook, Instagram)
            subject: this.document.name, // fi. for email
            url: this.document.url,
        };
        this.socialSharing
            .shareWithOptions(options)
            .then(_result => {
                console.log(_result);
            })
            .catch(_error => {
                console.log(_error);
                this.eventService.sendEvent('open_alert_dialog', {
                    message: 'AlertMessage.SharingError',
                    callback: null,
                });
            });
    }
}
