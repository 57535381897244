import { Injectable } from '@angular/core';

import { Menu } from '../app.interface';

import { AuthService } from './auth.service';
import { RoleService } from './role.service';

@Injectable()
export class MenuService {
    private ACCOUNT_MENU = [];

    private ACTION_MENU = [
        // {
        //     state: 'schedule_jobs',
        //     name: 'Menu.JobsToSchedule',
        //     type: 'action',
        //     icon: 'date_range'
        // },
        {
            state: '/main/jobs/create',
            name: 'Menu.CreateJob',
            type: 'link',
            icon: 'work',
            permissions: [RoleService.CREATE_NEW_JOBS],
        },
    ];

    private MAIN_MENU = [
        {
            state: '/main/jobs',
            name: 'Menu.Jobs',
            type: 'link',
            icon: 'work',
            permissions: [RoleService.VIEW_ALL_JOBS, RoleService.VIEW_OWN_JOBS],
        },
        {
            state: '/main/contacts',
            name: 'Menu.Contacts',
            type: 'link',
            icon: 'person',
            permissions: [
                RoleService.VIEW_ALL_CONTACTS,
                RoleService.VIEW_OWN_CONTACTS,
            ],
        },
        {
            state: '/main/time-tracking',
            name: 'Menu.TimeTracking',
            type: 'link',
            icon: 'query_builder',
            permissions: [
                RoleService.VIEW_ALL_TIME_EVENTS,
                RoleService.VIEW_OWN_TIME_EVENTS,
            ],
        },
        {
            state: '/main/settings',
            name: 'Menu.Settings',
            type: 'link',
            icon: 'settings',
            permissions: [],
        },
        {
            state: 'sign_out',
            name: 'Menu.SignOut',
            type: 'action',
            icon: 'power_settings_new',
            permissions: [],
        },
    ];

    constructor(private auth: AuthService) {}
    /*
        filter menus by user's permission
    */
    filterMenu(_menu: any): boolean {
        const _userInfo = this.auth.getUserInfo();
        if (_menu.children) {
            _menu.children = _menu.children.filter(_child => {
                return this.filterMenu(_child);
            });
            if (_menu.children.length > 0) {
                return true;
            }
        } else {
            if (_menu.permissions.length > 0) {
                for (const permission of _menu.permissions) {
                    if (_userInfo.permissions.includes(permission)) {
                        return true;
                    }
                }
            } else {
                return true;
            }
        }
        return false;
    }
    /*
        return all menu items
    */
    getMenuItems(): any {
        const menuItems = {
            account: this.getAccountMenu(),
            action: this.getActionMenu(),
            main: this.getMainMenu(),
        };
        return menuItems;
    }
    /*
        return menu items of left side navbar
    */
    getMainMenu(): Menu[] {
        return JSON.parse(JSON.stringify(this.MAIN_MENU)).filter(_menu => {
            return this.filterMenu(_menu);
        });
    }
    /*
        return menu items of top right
    */
    getAccountMenu(): Menu[] {
        return JSON.parse(JSON.stringify(this.ACCOUNT_MENU)).filter(_menu => {
            return this.filterMenu(_menu);
        });
    }
    /*
        return menu items of top right
    */
    getActionMenu(): Menu[] {
        return JSON.parse(JSON.stringify(this.ACTION_MENU)).filter(_menu => {
            return this.filterMenu(_menu);
        });
    }
}
