import {
    OnInit,
    OnDestroy,
    NgZone,
    Component,
    Inject,
    ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { Tag, Job } from '../../app.interface';
import { JobService, EventService, CacheService } from '../../services';

@Component({
    selector: 'app-job-tag-dialog',
    templateUrl: './job-tag.dialog.html',
    styleUrls: [],
})
export class JobTagDialog implements OnInit, OnDestroy {
    @ViewChild('tagList', { static: false }) public tagList;
    loading = false;
    job: Job;
    tags: Tag[] = [];
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private zone: NgZone,
        private jobService: JobService,
        private eventService: EventService,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<JobTagDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        refresh data
    */
    refreshData(): void {
        const _syncIds = this.cacheService.getDataList('sync_ids');
        this.data.job.id = _syncIds[this.data.job.id]
            ? _syncIds[this.data.job.id]
            : this.data.job.id;
        this.job = this.data.job;
        this.tags = this.data.tags;
        this.tags.forEach(_tag => {
            const _findex = this.job.tags.findIndex(
                _jtag => _jtag.id == _tag.id,
            );
            _tag.selected = _findex > -1 ? true : false;
        });
    }
    /*
        called when user click 'save' button
    */
    onSubmit(): void {
        this.loading = true;
        this.spinner.show();
        const _tagIds = this.tagList.selectedOptions.selected.map(
            _option => _option.value,
        );
        const _tags = [];
        this.tags.forEach(_tag => {
            if (_tagIds.includes(_tag.id)) {
                _tags.push(_tag);
            }
        });
        this.jobService
            .updateTags(this.job.id, _tags)
            .pipe(
                finalize(() => {
                    this.zone.run(() => {
                        this.loading = false;
                        this.spinner.hide();
                    });
                }),
            )
            .subscribe(() => {
                this.dialogRef.close({
                    refresh: true,
                });
            });
    }
}
