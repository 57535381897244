// angular
import { Injectable } from '@angular/core';
import { Observable, Subscription, of, from, forkJoin, throwError } from 'rxjs';
import { finalize, tap, switchMap, delay, catchError } from 'rxjs/operators';

import { CacheService } from './cache.service';
import { ChecklistStepService } from './checklist-step.service';
import { ChecklistService } from './checklist.service';
import { CompanyService } from './company.service';
import { ConfigService } from './config.service';
import { ConnectionService } from './connection.service';
import { ContactService } from './contact.service';
import { EventService } from './event.service';
import { ItemService } from './item.service';
import { JobService } from './job.service';
import { ProductService } from './product.service';
import { RequestService } from './request.service';
import { ServiceobjectService } from './serviceobject.service';
import { StorageService } from './storage.service';
import { TagService } from './tag.service';
import { TimeEventService } from './time-event.service';
import { UnitService } from './unit.service';
import { UserService } from './user.service';

@Injectable()
export class SyncDataService {
    public synchronizing = false;
    private dataList = [];
    private syncIds = null;
    private syncSubscription: Subscription;
    private syncInterval;
    constructor(
        private jobService: JobService,
        private contactService: ContactService,
        private serviceobjectService: ServiceobjectService,
        private productService: ProductService,
        private checklistService: ChecklistService,
        private companyService: CompanyService,
        private itemService: ItemService,
        private userService: UserService,
        private unitService: UnitService,
        private tagService: TagService,
        private eventService: EventService,
        private timeEventService: TimeEventService,
        private checklistStepService: ChecklistStepService,
        private storageService: StorageService,
        private requestService: RequestService,
        private connectionService: ConnectionService,
        private cacheService: CacheService,
    ) {
        this.refreshDataList();

        this.eventService.getEvent().subscribe(payload => {
            if (payload.command == 'sync_data') {
                this.syncData();
            } else if (payload.command == 'connection_updated') {
                if (this.connectionService.getConnected() == true) {
                    this.syncData();
                } else {
                    this.clearSync();
                }
            } else if (payload.command == 'logged-out') {
                this.clearSync();
            }
        });
    }
    // clear intervals
    clearSync(): void {
        this.synchronizing = false;
        this.syncSubscription?.unsubscribe();
        clearTimeout(this.syncInterval);
    }
    // get data list
    getDataList(): any[] {
        return this.dataList;
    }
    // refresh data list
    refreshDataList(): void {
        this.dataList = [
            {
                id: 1,
                text: 'Menu.Jobs',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // jobs(user involved in)
            {
                id: 2,
                text: 'Menu.Contacts',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // contacts(all)
            {
                id: 3,
                text: 'Label.ServiceObjects',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // serviceobjects(all)
            {
                id: 4,
                text: 'Label.Products',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // products(have no serviceobject)
            {
                id: 5,
                text: 'Label.Checklists',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // checklists(all)
            {
                id: 6,
                text: 'Label.Customers',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // companies(all)
            {
                id: 7,
                text: 'Label.Items',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // items(all)
            {
                id: 8,
                text: 'Label.Users',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // users(all->job_time_event)
            {
                id: 9,
                text: 'Label.Units',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // units(all)
            {
                id: 10,
                text: 'Label.Tags',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // tags(all)
            {
                id: 11,
                text: 'Label.TimeEvents',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // time_events(user, jobs)
            {
                id: 12,
                text: 'Label.ChecklistSteps',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // checklist_steps(jobs, checklists, products)
            {
                id: 13,
                text: 'Label.TemplateJobs',
                count: 0,
                loaded: false,
                loading: false,
                failed: false,
            }, // template_jobs(all)
        ];
    }
    // synchronize data list
    syncData(): void {
        this.clearSync();
        if (this.connectionService.getConnected() == true) {
            this.synchronizing = true;
            this.eventService.sendEvent('synchronizing_started');
            this.syncSubscription = this.uploadData()
                .pipe(
                    tap(res => {
                        this.syncIds = res.ids;
                        this.cacheService.setDataList('sync_ids', res.ids);
                    }),
                    switchMap(() => this.downloadData()),
                    finalize(() => {
                        this.synchronizing = false;
                        this.eventService.sendEvent('synchronizing_finished', {
                            ids: this.syncIds,
                        });
                        this.syncInterval = setTimeout(() => {
                            this.syncData();
                        }, ConfigService.settings.dataSyncInterval * 60 * 60 * 1000);
                    }),
                )
                .subscribe();
        }
    }
    // download data
    downloadData(): Observable<any> {
        const _requests = [];
        _requests.push(this.getJobs());
        _requests.push(
            of('delay').pipe(
                delay(100),
                switchMap(() => this.getContacts()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(200),
                switchMap(() => this.getServiceobjects()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(300),
                switchMap(() => this.getProducts()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(400),
                switchMap(() => this.getChecklists()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(500),
                switchMap(() => this.getCompanies()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(600),
                switchMap(() => this.getItems()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(700),
                switchMap(() => this.getUsers()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(800),
                switchMap(() => this.getUnits()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(900),
                switchMap(() => this.getTags()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(1000),
                switchMap(() => this.getTimeEvents()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(1100),
                switchMap(() => this.getChecklistSteps()),
            ),
        );
        _requests.push(
            of('delay').pipe(
                delay(1200),
                switchMap(() => this.getTemplateJobs()),
            ),
        );
        return forkJoin(_requests).pipe(
            finalize(() => {
                this.eventService.sendEvent('downloading_finished', {
                    list: this.dataList,
                });
            }),
            catchError(err => {
                this.eventService.sendEvent('downloading_error');
                return throwError(err);
            }),
        );
    }
    // upload data
    uploadData(): Observable<any> {
        this.eventService.sendEvent('uploading_started');
        return from(this.storageService.getDataList('actions')).pipe(
            switchMap(res => {
                return this.requestService.postRequest('/sync/action', {
                    actions: res,
                });
            }),
            finalize(() => {
                this.eventService.sendEvent('uploading_finished');
                this.storageService.clearDataList('actions');
            }),
            catchError(() => {
                this.eventService.sendEvent('uploading_error');
                return of([]);
            }),
        );
    }
    // get jobs
    getJobs(): Observable<any> {
        this.dataList[0].loading = true;
        return this.jobService.getAll(0).pipe(
            tap(res => {
                this.dataList[0].count = res.length;
            }),
            catchError(() => {
                this.dataList[0].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('jobs', res)),
            finalize(() => {
                this.dataList[0].loaded = true;
                this.dataList[0].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get contacts
    getContacts(): Observable<any> {
        this.dataList[1].loading = true;
        return this.contactService.getAll().pipe(
            tap(res => {
                this.dataList[1].count = res.length;
            }),
            catchError(() => {
                this.dataList[1].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('contacts', res)),
            finalize(() => {
                this.dataList[1].loaded = true;
                this.dataList[1].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get serviceobjects
    getServiceobjects(): Observable<any> {
        this.dataList[2].loading = true;
        return this.serviceobjectService.getAll().pipe(
            tap(res => {
                this.dataList[2].count = res.length;
            }),
            catchError(() => {
                this.dataList[2].failed = true;
                return of([]);
            }),
            switchMap(res =>
                this.storageService.setDataList('serviceobjects', res),
            ),
            finalize(() => {
                this.dataList[2].loaded = true;
                this.dataList[2].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get products
    getProducts(): Observable<any> {
        this.dataList[3].loading = true;
        return this.productService.getAll().pipe(
            tap(res => {
                this.dataList[3].count = res.length;
            }),
            catchError(() => {
                this.dataList[3].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('products', res)),
            finalize(() => {
                this.dataList[3].loaded = true;
                this.dataList[3].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get checklists
    getChecklists(): Observable<any> {
        this.dataList[4].loading = true;
        return this.checklistService.getAll().pipe(
            tap(res => {
                this.dataList[4].count = res.length;
            }),
            catchError(() => {
                this.dataList[4].failed = true;
                return of([]);
            }),
            switchMap(res =>
                this.storageService.setDataList('checklists', res),
            ),
            finalize(() => {
                this.dataList[4].loaded = true;
                this.dataList[4].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get companies
    getCompanies(): Observable<any> {
        this.dataList[5].loading = true;
        return this.companyService.getAll().pipe(
            tap(res => {
                this.dataList[5].count = res.length;
            }),
            catchError(() => {
                this.dataList[5].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('companies', res)),
            finalize(() => {
                this.dataList[5].loaded = true;
                this.dataList[5].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get items
    getItems(): Observable<any> {
        this.dataList[6].loading = true;
        return this.itemService.getAll().pipe(
            tap(res => {
                this.dataList[6].count = res.length;
            }),
            catchError(() => {
                this.dataList[6].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('items', res)),
            finalize(() => {
                this.dataList[6].loaded = true;
                this.dataList[6].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get users
    getUsers(): Observable<any> {
        this.dataList[7].loading = true;
        return this.userService.getAll().pipe(
            tap(res => {
                this.dataList[7].count = res.length;
            }),
            catchError(() => {
                this.dataList[7].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('users', res)),
            finalize(() => {
                this.dataList[7].loaded = true;
                this.dataList[7].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get units
    getUnits(): Observable<any> {
        this.dataList[8].loading = true;
        return this.unitService.getAll().pipe(
            tap(res => {
                this.dataList[8].count = res.length;
            }),
            catchError(() => {
                this.dataList[8].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('units', res)),
            finalize(() => {
                this.dataList[8].loaded = true;
                this.dataList[8].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get tags
    getTags(): Observable<any> {
        this.dataList[9].loading = true;
        return this.tagService.getAll().pipe(
            tap(res => {
                this.dataList[9].count = res.length;
            }),
            catchError(() => {
                this.dataList[9].failed = true;
                return of([]);
            }),
            switchMap(res => this.storageService.setDataList('tags', res)),
            finalize(() => {
                this.dataList[9].loaded = true;
                this.dataList[9].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get time events
    getTimeEvents(): Observable<any> {
        this.dataList[10].loading = true;
        return this.timeEventService.getAll().pipe(
            tap(res => {
                this.dataList[10].count = res.length;
            }),
            catchError(() => {
                this.dataList[10].failed = true;
                return of([]);
            }),
            switchMap(res =>
                this.storageService.setDataList('time_events', res),
            ),
            finalize(() => {
                this.dataList[10].loaded = true;
                this.dataList[10].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get checklist steps
    getChecklistSteps(): Observable<any> {
        this.dataList[11].loading = true;
        return this.checklistStepService.getAll().pipe(
            tap(res => {
                this.dataList[11].count = res.length;
            }),
            catchError(() => {
                this.dataList[11].failed = true;
                return of([]);
            }),
            switchMap(res =>
                this.storageService.setDataList('checklist_steps', res),
            ),
            finalize(() => {
                this.dataList[11].loaded = true;
                this.dataList[11].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
    // get template jobs
    getTemplateJobs(): Observable<any> {
        this.dataList[12].loading = true;
        return this.jobService.getAll(1).pipe(
            tap(res => {
                this.dataList[12].count = res.length;
            }),
            catchError(() => {
                this.dataList[12].failed = true;
                return of([]);
            }),
            switchMap(res =>
                this.storageService.setDataList('template_jobs', res),
            ),
            finalize(() => {
                this.dataList[12].loaded = true;
                this.dataList[12].loading = false;
                this.eventService.sendEvent('downloading_list', {
                    list: this.dataList,
                });
            }),
        );
    }
}
