// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

// service
import { MIME_TYPES } from '../app.constant';
import { ChecklistStep, ChecklistStepAttachment } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class ChecklistStepService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all checklists
    */
    getAll(): Observable<ChecklistStep[]> {
        const params = new URLSearchParams();
        return this.requestService
            .getParamRequest('/checklist_step/all?' + params.toString(), {})
            .pipe(map(res => res.checklist_steps));
    }
    /*
        update checklist_step
    */
    updateItem(
        _apiData: ChecklistStep,
        _fullData: ChecklistStep,
    ): Observable<any> {
        return this.storageService.updateDataItem(
            'checklist_steps',
            _apiData,
            _fullData,
            true,
        );
    }
    /*
        upload attachments
    */
    uploadAttachments(_step: ChecklistStep, _images: any[]): Observable<any> {
        _images.forEach(_fileInfo => {
            _fileInfo['id'] = uuid();
        });
        return this.storageService
            .createAction('attachments', 'upload', {
                step_id: _step.id,
                files: _images,
            })
            .pipe(
                switchMap(() => {
                    _images.forEach(_fileInfo => {
                        _step.checklist_step_attachments.push({
                            id: _fileInfo.id,
                            step_id: _step.id,
                            base64: _fileInfo.src.split(',')[1],
                            original_name: _fileInfo.name,
                            physical_name: '',
                            mimeType:
                                MIME_TYPES[_fileInfo.name.split('.').pop()],
                        });
                    });
                    return this.storageService.updateDataItem(
                        'checklist_steps',
                        {},
                        _step,
                        false,
                    );
                }),
            );
    }
    /*
        add checklist_steps
    */
    addChecklistSteps(_checklistSteps: ChecklistStep[]): Observable<any> {
        _checklistSteps.forEach(_step => {
            _step.checklist_step_attachments = [];
        });
        return this.storageService
            .createAction('checklist_steps_list', 'add', {
                checklist_steps: _checklistSteps,
            })
            .pipe(
                switchMap(() => {
                    return this.storageService
                        .getDataList('checklist_steps')
                        .pipe(
                            switchMap(_res => {
                                _res.push(..._checklistSteps);
                                return this.storageService.setDataList(
                                    'checklist_steps',
                                    _res,
                                );
                            }),
                        );
                }),
            );
    }
    /*
        update checklist_steps
    */
    updateChecklistSteps(_checklistSteps: ChecklistStep[]): Observable<any> {
        const _requestSteps = JSON.parse(JSON.stringify(_checklistSteps));
        _requestSteps.forEach(_step => {
            _step.checklist_step_attachments = [];
        });
        return this.storageService
            .createAction('checklist_steps_list', 'update', {
                checklist_steps: _requestSteps,
            })
            .pipe(
                switchMap(() => {
                    return this.storageService
                        .getDataList('checklist_steps')
                        .pipe(
                            switchMap(_res => {
                                _checklistSteps.forEach(_step => {
                                    const _findIndex = _res.findIndex(
                                        _item => _item.id == _step.id,
                                    );
                                    if (_findIndex > -1) {
                                        _res[_findIndex] = _step;
                                    }
                                });
                                return this.storageService.setDataList(
                                    'checklist_steps',
                                    _res,
                                );
                            }),
                        );
                }),
            );
    }
    /*
        delete checklist_step
    */
    deleteItem(_stepId: any): Observable<any> {
        return this.storageService.deleteDataItem(
            'checklist_steps',
            _stepId,
            true,
        );
    }
    /*
        update attachment
    */
    updateAttachment(
        _apiData: ChecklistStepAttachment,
        _fullData: ChecklistStepAttachment,
    ): Observable<any> {
        return this.storageService
            .updateItem('attachments', _apiData, _fullData)
            .pipe(
                switchMap(_attachment => {
                    return this.storageService
                        .getDataItem('checklist_steps', _fullData.step_id)
                        .pipe(
                            switchMap((_step: ChecklistStep) => {
                                const _findIndex = _step.checklist_step_attachments.findIndex(
                                    _item => _item.id == _fullData.id,
                                );
                                if (_findIndex > -1) {
                                    _step.checklist_step_attachments[
                                        _findIndex
                                    ] = _attachment;
                                }
                                return this.storageService.updateDataItem(
                                    'checklist_steps',
                                    {},
                                    _step,
                                    false,
                                );
                            }),
                        );
                }),
            );
    }
    /*
        delete attachment
    */
    deleteAttachment(_id: any, _stepId: any): Observable<any> {
        return this.storageService.deleteItem('attachments', _id).pipe(
            switchMap(() => {
                return this.storageService
                    .getDataItem('checklist_steps', _stepId)
                    .pipe(
                        switchMap((_step: ChecklistStep) => {
                            const _findIndex = _step.checklist_step_attachments.findIndex(
                                _item => _item.id == _id,
                            );
                            if (_findIndex > -1) {
                                _step.checklist_step_attachments.splice(
                                    _findIndex,
                                    1,
                                );
                            }
                            return this.storageService.updateDataItem(
                                'checklist_steps',
                                {},
                                _step,
                                false,
                            );
                        }),
                    );
            }),
        );
    }
}
