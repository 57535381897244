import { NgZone, OnDestroy, OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User, Menu } from '../../../app.interface';
import {
    AuthService,
    EventService,
    MenuService,
    ConnectionService,
    SyncDataService,
} from '../../../services';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: [],
})
export class AppSidebarComponent implements OnInit, OnDestroy {
    menuItems: any;
    userInfo: User;
    connected = true;
    synchronizing = false;
    public config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private zone: NgZone,
        private router: Router,
        private authService: AuthService,
        private eventService: EventService,
        private menuService: MenuService,
        private connectionService: ConnectionService,
        private syncDataService: SyncDataService,
    ) {}

    ngOnInit(): void {
        this.menuItems = this.menuService.getMenuItems();
        this.userInfo = this.authService.getUserInfo();
        this.connected = this.connectionService.getConnected();
        this.synchronizing = this.syncDataService.synchronizing;
        const routeUrl = this.router.url;
        const currentUrl = routeUrl.split('/');
        this.menuItems.main.forEach(_item => {
            if (currentUrl.indexOf(_item.state) > 0) {
                _item.selected = true;
            }
        });

        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'connection_updated') {
                    this.zone.run(() => {
                        this.connected = payload.data.connected;
                    });
                } else if (payload.command == 'synchronizing_started') {
                    this.zone.run(() => {
                        setTimeout(() => {
                            this.synchronizing = true;
                        }, 100);
                    });
                } else if (payload.command == 'synchronizing_finished') {
                    this.zone.run(() => {
                        setTimeout(() => {
                            this.synchronizing = false;
                        }, 100);
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        called when user click menu item
    */
    onMenuClick(_menu: Menu): void {
        this.eventService.sendEvent('toggle_sidenav');
        switch (_menu.type) {
            case 'link':
                this.router.navigate([_menu.state]);
                break;
            case 'action':
                this.eventService.sendEvent(_menu.state, {
                    ref: null,
                    callback: null,
                });
                break;
        }
    }
    /*
        called when user click 'refresh' icon
    */
    syncData(): void {
        this.eventService.sendEvent('sync_data');
    }
}
