// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// service
import { Unit } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class UnitService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all unit
    */
    getAll(): Observable<Unit[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/unit/all?' + params.toString(), {})
            .pipe(map(res => res.units));
    }
    /*
        get unit list
    */
    getList(): Observable<Unit[]> {
        return this.storageService.getDataList('units');
    }
    /*
        get unit by id
    */
    getItemById(_id: any): Observable<Unit> {
        return this.storageService.getDataItem('units', _id);
    }
}
