import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/*
    Event Service
*/
@Injectable()
export class EventService {
    private event = new Subject<any>();
    /*
        broadcast event
    */
    sendEvent(command: string, message?: any): void {
        console.log(command);
        console.log(message);
        this.event.next({ command: command, data: message });
    }
    /*
        clear event
    */
    clearEvent(): void {
        this.event.next();
    }
    /*
        subscribe event
    */
    getEvent(): Observable<any> {
        return this.event.asObservable();
    }
}
