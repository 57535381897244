import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxExtendedPdfViewerComponent } from './ngx-extended-pdf-viewer.component';

@NgModule({
    imports: [CommonModule],
    declarations: [NgxExtendedPdfViewerComponent],
    exports: [NgxExtendedPdfViewerComponent],
})
export class NgxExtendedPdfViewerModule {}
