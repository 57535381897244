import { NgModule } from '@angular/core';

import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';

import { LoginComponent } from './login/login.component';

@NgModule({
    imports: [SharedModule, PipesModule, ComponentsModule],
    declarations: [LoginComponent],
    providers: [],
})
export class AuthModule {}
