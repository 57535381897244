import { DatePipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfigService } from '../services';

@Pipe({
    name: 'localDate',
})
export class LocalDatePipe extends DatePipe implements PipeTransform {
    constructor(translate: TranslateService) {
        super(translate.currentLang);
    }

    transform(value: any, args?: any): any {
        const _value = value;
        let _format = args;
        // use ISO format as default
        if (_format == 'defaultDateTime') {
            _format = ConfigService.settings.dateFormat.dateTime;
        } else if (_format == 'defaultDate') {
            _format = ConfigService.settings.dateFormat.date;
        } else if (_format == 'defaultTime') {
            _format = ConfigService.settings.dateFormat.time;
        }
        return super.transform(_value, _format);
    }
}
