import { OnInit, NgZone, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Language } from '../../../app.interface';
import {
    AuthService,
    RequestService,
    CacheService,
    UtilService,
    EventService,
    ConfigService,
} from '../../../services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [],
})
export class LoginComponent implements OnInit {
    loading = false;
    return = '';
    form: FormGroup;
    languages: Language[] = ConfigService.settings.i18n.availableLanguages;
    currentLanguage: Language;
    protected _onDestroy = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private requestService: RequestService,
        private utilService: UtilService,
        private eventService: EventService,
        private cacheService: CacheService,
        private translate: TranslateService,
        private zone: NgZone,
        private spinner: NgxSpinnerService,
    ) {}

    ngOnInit(): void {
        this.languages.forEach(_lang => {
            _lang.country = _lang.culture.split('-')[1];
            if (_lang.code == this.translate.currentLang) {
                this.currentLanguage = _lang;
            }
        });

        this.form = this.fb.group({
            email: [
                '',
                Validators.compose([Validators.required, Validators.email]),
            ],
            password: ['', Validators.compose([Validators.required])],
        });
        // Get the query params
        this.route.queryParams.subscribe(
            params => (this.return = params['return'] || '/main/jobs'),
        );
    }

    /*
        called when user click language button
    */
    selectLanguage(_selected: Language): void {
        if (_selected.code !== this.translate.currentLang) {
            this.loading = true;
            this.spinner.show();
            this.translate
                .use(_selected.code)
                .pipe(
                    finalize(() => {
                        this.zone.run(() => {
                            this.loading = false;
                            this.spinner.hide();
                        });
                    }),
                )
                .subscribe(() => {
                    this.requestService.setLang(_selected.code);
                    this.cacheService.setLang(_selected.code);
                    moment.locale(_selected.code);
                    this.utilService.languages = [];
                    this.utilService.countries = [];
                    this.eventService.sendEvent('language_updated', {
                        code: _selected.code,
                    });
                });
        }
    }

    onSubmit(): void {
        if (this.form.valid) {
            const credentials = this.form.value;
            this.loading = true;
            this.spinner.show();
            this.authService
                .login(credentials.email, credentials.password)
                .pipe(
                    finalize(() => {
                        this.zone.run(() => {
                            this.loading = false;
                            this.spinner.hide();
                        });
                    }),
                )
                .subscribe(() => {
                    this.router.navigateByUrl(this.return, {
                        replaceUrl: true,
                    });
                });
        }
    }
}
