// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// service
import { Company } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class CompanyService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all companies
    */
    getAll(): Observable<Company[]> {
        const params = new URLSearchParams();

        return this.requestService
            .getParamRequest('/company/all?' + params.toString(), {})
            .pipe(map(res => res.companies));
    }
    /*
        get company list
    */
    getList(): Observable<Company[]> {
        return this.storageService.getDataList('companies');
    }
    /*
        get company by id
    */
    getItemById(_id: any): Observable<Company> {
        return this.storageService.getDataItem('companies', _id);
    }
}
