import { OnInit, OnDestroy, NgZone, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { Document } from '../../app.interface';
import { JobService, ServiceobjectService, EventService } from '../../services';

@Component({
    selector: 'app-documents-dialog',
    templateUrl: './documents.dialog.html',
    styleUrls: [],
})
export class DocumentsDialog implements OnInit, OnDestroy {
    loading = false;
    documents: Document[] = [];
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private zone: NgZone,
        private jobService: JobService,
        private serviceobjectService: ServiceobjectService,
        private eventService: EventService,
        public dialogRef: MatDialogRef<DocumentsDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.refreshData();

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        get documents
    */
    refreshData(): void {
        this.loading = true;
        this.spinner.show();
        const _requests = [];

        if (this.data.jobId) {
            _requests.push(this.jobService.getItemById(this.data.jobId));
        }
        if (this.data.serviceobjectId) {
            _requests.push(
                this.serviceobjectService.getItemById(
                    this.data.serviceobjectId,
                ),
            );
        }

        forkJoin(_requests)
            .pipe(
                finalize(() => {
                    this.zone.run(() => {
                        this.loading = false;
                        this.spinner.hide();
                    });
                }),
            )
            .subscribe(_res => {
                _res.forEach((_item: any) => {
                    _item.documents.forEach((_document: Document) => {
                        _document.created_at = _document.created_at.replace(
                            /-/g,
                            '/',
                        );
                        _document.isPdf =
                            _document.name.split('.').pop() === 'pdf';
                    });
                    this.documents.push(..._item.documents);
                });
            });
    }
    /*
        called when user click document item
    */
    selectDocument(_document: Document): void {
        this.eventService.sendEvent('open_document_preview_dialog', {
            document: _document,
            callback: null,
        });
    }
}
