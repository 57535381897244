import { Component, Inject, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { MIME_TYPES } from '../../app.constant';
import { ChecklistStepAttachment } from '../../app.interface';
import {
    ChecklistStepService,
    EventService,
    CacheService,
} from '../../services';

@Component({
    selector: 'app-attachment-dialog',
    templateUrl: './attachment.dialog.html',
    styleUrls: [],
})
export class AttachmentDialog implements OnInit, OnDestroy {
    loading = false;
    attachment: ChecklistStepAttachment;
    form: FormGroup;
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        private zone: NgZone,
        private checklistStepService: ChecklistStepService,
        private eventService: EventService,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<AttachmentDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.form = this.fb.group({
            name: [
                this.attachment.id
                    ? this.attachment.original_name
                    : this.attachment.file.name,
                Validators.compose([Validators.required]),
            ],
        });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        refresh data
    */
    refreshData(): void {
        const _syncIds = this.cacheService.getDataList('sync_ids');
        if (this.data.attachment) {
            this.data.attachment.id = _syncIds[this.data.attachment.id]
                ? _syncIds[this.data.attachment.id]
                : this.data.attachment.id;
            this.attachment = this.data.attachment;
            this.attachment.mimeType =
                MIME_TYPES[this.attachment.original_name.split('.').pop()];
        }
    }
    /*
        called when user click 'save' button
    */
    onSubmit(): void {
        if (this.form.valid) {
            this.loading = true;
            this.spinner.show();
            const attachmentData = {
                id: this.attachment.id,
                original_name: this.form.value.name,
            };
            this.attachment.original_name = this.form.value.name;
            this.checklistStepService
                .updateAttachment(attachmentData, this.attachment)
                .pipe(
                    finalize(() => {
                        this.zone.run(() => {
                            this.loading = false;
                            this.spinner.hide();
                        });
                    }),
                )
                .subscribe(() => {
                    this.dialogRef.close({
                        action: 'update',
                        attachment: this.attachment,
                    });
                });
        }
    }
    /*
        called when user click 'remove' button
    */
    remove(): void {
        this.loading = true;
        this.spinner.show();
        this.checklistStepService
            .deleteAttachment(this.attachment.id, this.attachment.step_id)
            .pipe(
                finalize(() => {
                    this.zone.run(() => {
                        this.loading = false;
                        this.spinner.hide();
                    });
                }),
            )
            .subscribe(() => {
                this.attachment.original_name = this.form.value.name;
                this.dialogRef.close({
                    action: 'remove',
                    attachment: this.attachment,
                });
            });
    }
}
