// angular
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';

// service
import { TimeEvent, TimeOverview } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';
import { UtilService } from './util.service';

@Injectable()
export class TimeEventService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
        private utilService: UtilService,
    ) {}
    /*
        get all time-events
    */
    getAll(): Observable<TimeEvent[]> {
        return this.requestService
            .getParamRequest('/time_event/all', {})
            .pipe(map(res => res.time_events));
    }
    /*
        get time-event list
    */
    getList(): Observable<TimeEvent[]> {
        return this.storageService.getDataList('time_events').pipe(
            switchMap(_events => {
                return forkJoin(
                    this.storageService.getDataList('jobs'),
                    this.storageService.getDataList('serviceobjects'),
                ).pipe(
                    tap(_res => {
                        const _jobs = _res[0];
                        const _serviceobjects = _res[1];
                        _events.forEach(_event => {
                            this.utilService.adjustTimeEvent(_event);
                            if (_event.job_id != null) {
                                _event.job = _jobs.find(
                                    _item => _item.id == _event.job_id,
                                );
                                if (_event.job?.serviceobject_id) {
                                    _event.job.serviceobject = _serviceobjects.find(
                                        _item =>
                                            _item.id ==
                                            _event.job.serviceobject_id,
                                    );
                                }
                            }
                        });
                    }),
                    map(() => _events),
                );
            }),
        );
    }
    /*
        get time-event overview with specific duration
    */
    getOverview(
        _start?: string,
        _end?: string,
        _jobId?: number,
        _userId?: number,
    ): Observable<TimeOverview> {
        const params = new URLSearchParams();
        if (_start) params.append('start', _start);
        if (_end) params.append('end', _end);
        if (_jobId) params.append('job_id', _jobId.toString());
        if (_userId) params.append('user_id', _userId.toString());
        return this.requestService
            .getParamRequest('/time_event/overview?' + params.toString(), {})
            .pipe(
                tap(res => {
                    this.utilService.adjustTimeOverview(res.overview);
                }),
                map(res => res.overview),
            );
    }
    /*
        create time-event
    */
    createItem(_apiData: TimeEvent, _fullData: TimeEvent): Observable<any> {
        return this.storageService
            .createDataItem('time_events', _apiData, _fullData, true)
            .pipe(
                switchMap(_res => {
                    // imitate TimeEventObserver of server
                    return this.storageService
                        .getDataItem('jobs', _res.job_id)
                        .pipe(
                            switchMap(_job => {
                                if (_job.state == 4) {
                                    _job.state = 5;
                                    return this.storageService
                                        .updateDataItem(
                                            'jobs',
                                            {
                                                id: _job.id,
                                                state: _job.state,
                                            },
                                            _job,
                                            false,
                                        )
                                        .pipe(map(() => _res.id));
                                } else {
                                    return of(_res.id);
                                }
                            }),
                        );
                }),
            );
    }
    /*
        get time-event by id
    */
    getItemById(_id: any): Observable<TimeEvent> {
        return this.storageService.getDataItem('time_events', _id).pipe(
            tap(res => {
                this.utilService.adjustTimeEvent(res);
            }),
        );
    }
    /*
        update time-event
    */
    updateItem(_apiData: TimeEvent, _fullData: TimeEvent): Observable<any> {
        return this.storageService.updateDataItem(
            'time_events',
            _apiData,
            _fullData,
            true,
        );
    }
    /*
        delete time-event
    */
    deleteItem(_id: any): Observable<any> {
        return this.storageService.deleteDataItem('time_events', _id, true);
    }
}
