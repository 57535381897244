import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';
import { OnDestroy, OnInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SyncDataService, EventService } from '../../services';

@Component({
    selector: 'app-sync-data-dialog',
    templateUrl: './sync-data.dialog.html',
    styleUrls: [],
    animations: [
        // the fade-in/fade-out animation.
        trigger('fadeAnimation', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),
            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class SyncDataDialog implements OnInit, OnDestroy {
    uploading = false;
    dataList = [];
    protected _onDestroy = new Subject<void>();
    constructor(
        private syncDataService: SyncDataService,
        private eventService: EventService,
        public dialogRef: MatDialogRef<SyncDataDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.syncDataService.refreshDataList();
        this.dataList = this.syncDataService.getDataList();
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'uploading_started') {
                    this.uploading = true;
                } else if (payload.command == 'uploading_finished') {
                    this.uploading = false;
                } else if (payload.command == 'downloading_list') {
                    const _syncList = payload.data.list;
                    this.dataList.forEach((_item, _index) => {
                        if (_item.loading != _syncList[_index].loading) {
                            _item.loading = _syncList[_index].loading;
                        }
                        if (_item.loaded != _syncList[_index].loaded) {
                            _item.loaded = _syncList[_index].loaded;
                        }
                        if (_item.count != _syncList[_index].count) {
                            _item.count = _syncList[_index].count;
                        }
                    });
                } else if (payload.command == 'synchronizing_finished') {
                    this.dialogRef.close();
                }
            });
    }

    ngOnInit(): void {
        this.syncDataService.syncData();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
