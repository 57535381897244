// angular
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

// service
import { Job, JobMessage, JobItem, TimeEvent } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';
import { UtilService } from './util.service';

@Injectable()
export class JobService {
    constructor(
        private requestService: RequestService,
        private utilService: UtilService,
        private storageService: StorageService,
    ) {}
    /*
        get all jobs
    */
    getAll(_isTemplate?: number): Observable<Job[]> {
        const params = new URLSearchParams();
        if (_isTemplate) params.append('is_template', _isTemplate.toString());
        return this.requestService
            .getParamRequest('/job/all?' + params.toString(), {})
            .pipe(map(res => res.jobs));
    }
    /*
        get job list
    */
    getList(_isTemplate?: number): Observable<Job[]> {
        const _key = _isTemplate && _isTemplate == 1 ? 'template_jobs' : 'jobs';
        return this.storageService.getDataList(_key).pipe(
            switchMap(_jobs => {
                return this.storageService.getDataList('serviceobjects').pipe(
                    tap(_serviceobjects => {
                        _jobs.forEach(_job => {
                            if (_job.serviceobject_id != null) {
                                _job.serviceobject = _serviceobjects.find(
                                    _item => _item.id == _job.serviceobject_id,
                                );
                            }
                            _job.routerLink = [
                                '/',
                                'main',
                                'jobs',
                                'detail',
                                _job.id,
                            ];
                            if (_job.appointments.length > 0) {
                                _job.nextAppointment =
                                    _job.appointments[
                                        _job.appointments.length - 1
                                    ];
                                _job.appointments.forEach(_appointment => {
                                    const _start = moment.utc(
                                        _appointment.time_from,
                                    );
                                    if (_start.isSameOrAfter(moment.utc())) {
                                        _job.nextAppointment = _appointment;
                                    }
                                });
                            }
                        });
                    }),
                    map(() => _jobs),
                );
            }),
        );
    }
    /*
        create job
    */
    createItem(_apiData: Job, _fullData: Job): Observable<any> {
        return this.storageService
            .createDataItem('jobs', _apiData, _fullData, true)
            .pipe(map(res => res.id));
    }
    /*
        get job by id
    */
    getItemById(_id: any, _isTemplate?: number): Observable<Job> {
        const _key = _isTemplate && _isTemplate == 1 ? 'template_jobs' : 'jobs';
        return this.storageService.getDataItem(_key, _id).pipe(
            switchMap((_job: Job) => {
                return forkJoin(
                    this.storageService.getDataList('serviceobjects'),
                    this.storageService.getDataList('companies'),
                    this.storageService.getDataList('time_events'),
                    this.storageService.getDataList('checklists'),
                    this.storageService.getDataList('checklist_steps'),
                    this.storageService.getDataList('products'),
                    this.storageService.getDataList('users'),
                ).pipe(
                    tap(_res => {
                        const _serviceobjects = _res[0];
                        const _companies = _res[1];
                        const _timeEvents = _res[2];
                        const _checklists = _res[3];
                        const _checklistSteps = _res[4];
                        const _products = _res[5];
                        const _users = _res[6];
                        if (_job.serviceobject_id != null) {
                            _job.serviceobject = _serviceobjects.find(
                                _item => _item.id == _job.serviceobject_id,
                            );
                        }
                        if (_job.company_id != null) {
                            _job.company = _companies.find(
                                _item => _item.id == _job.company_id,
                            );
                        }
                        _job.time_events = _timeEvents.filter(
                            _item => _item.job_id == _job.id,
                        );
                        _job.checklists = _checklists.filter(_item =>
                            _job.checklists.includes(_item.id),
                        );
                        _job.checklist_steps = _checklistSteps.filter(
                            _item => _item.job_id == _job.id,
                        );
                        _job.products = _products.filter(_item =>
                            _job.products.includes(_item.id),
                        );

                        _job.documents.forEach(_document => {
                            if (_document.upload_user_id != null) {
                                _document.upload_user = _users.find(
                                    _item =>
                                        _item.id == _document.upload_user_id,
                                );
                            }
                        });
                        _job.time_events.forEach((_event: TimeEvent) => {
                            if (_event.user_id != null) {
                                _event.user = _users.find(
                                    _item => _item.id == _event.user_id,
                                );
                            }
                            this.utilService.adjustTimeEvent(_event);
                        });
                        if (_job.appointments.length > 0) {
                            _job.nextAppointment =
                                _job.appointments[_job.appointments.length - 1];
                            _job.appointments.forEach(_appointment => {
                                const _start = moment.utc(
                                    _appointment.time_from,
                                );
                                if (_start.isSameOrAfter(moment.utc())) {
                                    _job.nextAppointment = _appointment;
                                }
                            });
                        }
                        const _contacts = [];
                        if (_job.owner?.contact)
                            _contacts.push(_job.owner.contact);
                        if (_job.company?.contacts?.length > 0)
                            _contacts.push(..._job.company.contacts);
                        if (_job.serviceobject?.maincontact)
                            _contacts.push(_job.serviceobject.maincontact);
                        if (_job.serviceobject?.contacts?.length > 0)
                            _contacts.push(..._job.serviceobject.contacts);
                        _job.contacts = this.utilService.getUnique(
                            _contacts,
                            'id',
                        );
                        _job.time_overview = this.utilService.getTimeEventsOverview(
                            _job.time_events,
                        );
                        _job.planned_time_minutes = _job.planned_time_minutes
                            ? _job.planned_time_minutes
                            : 0;
                        _job.formattedPlannedTime = moment
                            .utc(
                                moment
                                    .duration(
                                        _job.planned_time_minutes,
                                        'minutes',
                                    )
                                    .asMilliseconds(),
                            )
                            .format('HH:mm:ss');
                    }),
                    map(() => _job),
                );
            }),
        );
    }
    /*
        update job
    */
    updateItem(_apiData: Job, _fullData: Job): Observable<any> {
        return this.storageService.updateDataItem(
            'jobs',
            _apiData,
            _fullData,
            true,
        );
    }
    /*
        get job message list
    */
    getMessageList(
        _jobId?: number,
        _userId?: number,
    ): Observable<JobMessage[]> {
        const params = new URLSearchParams();
        if (_jobId) params.append('job_id', _jobId.toString());
        if (_userId) params.append('message_id', _userId.toString());
        return this.requestService
            .getParamRequest('/job/message/list?' + params.toString(), {})
            .pipe(map(res => res.job_messages));
    }
    /*
        update job checklists
        _mode - 0: update, 1: add, 2: delete
    */
    updateChecklists(
        _id: any,
        _checklistIds: any[],
        _mode?: number,
    ): Observable<any> {
        const mode = _mode ? _mode : 0;
        return this.storageService
            .createAction('job_checklists', 'update', {
                mode: mode,
                job_id: _id,
                checklists: _checklistIds,
            })
            .pipe(
                switchMap(() => {
                    if (mode == 1) {
                        return this.storageService
                            .getDataList('checklist_steps')
                            .pipe(
                                switchMap(_steps => {
                                    const _clonedSteps = JSON.parse(
                                        JSON.stringify(_steps),
                                    );
                                    const _checklistSteps = _clonedSteps.filter(
                                        _step => {
                                            return (
                                                _checklistIds.includes(
                                                    _step.checklist_id,
                                                ) && _step.job_id == null
                                            );
                                        },
                                    );
                                    _checklistSteps.forEach(_step => {
                                        const _oldId = _step.id;
                                        _step.id = uuid();
                                        _step.job_id = _id;
                                        _checklistSteps.forEach(_item => {
                                            if (_item.parent_step == _oldId) {
                                                _item.parent_step = _step.id;
                                            }
                                        });
                                        _step.checklist_step_choices.forEach(
                                            _choice => {
                                                _choice.id = uuid();
                                                _choice.step_id = _step.id;
                                            },
                                        );
                                        _step.checklist_step_values.forEach(
                                            _value => {
                                                _value.id = uuid();
                                                _value.step_id = _step.id;
                                            },
                                        );
                                        _step.checklist_step_attachments = [];
                                    });
                                    return this.storageService
                                        .createAction(
                                            'checklist_steps_list',
                                            'add',
                                            {
                                                checklist_steps: _checklistSteps,
                                            },
                                        )
                                        .pipe(
                                            switchMap(() => {
                                                _steps.push(..._checklistSteps);
                                                return this.storageService.setDataList(
                                                    'checklist_steps',
                                                    _steps,
                                                );
                                            }),
                                        );
                                }),
                            );
                    }
                }),
            );
    }
    /*
        update job products
        _mode - 0: update, 1: add, 2: delete
    */
    updateProducts(
        _id: any,
        _productIds: any[],
        _mode?: number,
    ): Observable<any> {
        const mode = _mode ? _mode : 0;
        return this.storageService
            .createAction('job_products', 'update', {
                mode: mode,
                job_id: _id,
                products: _productIds,
            })
            .pipe(
                switchMap(() => {
                    if (mode == 1) {
                        return this.storageService
                            .getDataItem('jobs', _id)
                            .pipe(
                                switchMap(_job => {
                                    _job.products.push(..._productIds);
                                    return this.storageService.updateDataItem(
                                        'jobs',
                                        {},
                                        _job,
                                        false,
                                    );
                                }),
                            );
                    } else if (mode == 2) {
                        return this.storageService
                            .getDataItem('jobs', _id)
                            .pipe(
                                switchMap(_job => {
                                    _productIds.forEach(_productId => {
                                        const _findIndex = _job.products.findIndex(
                                            _item => _item == _productId,
                                        );
                                        if (_findIndex > -1) {
                                            _job.products.splice(_findIndex, 1);
                                        }
                                    });
                                    return this.storageService.updateDataItem(
                                        'jobs',
                                        {},
                                        _job,
                                        false,
                                    );
                                }),
                            );
                    }
                }),
            );
    }
    /*
        create job item
    */
    createMaterial(_apiData: JobItem, _fullData: JobItem): Observable<any> {
        return this.storageService
            .createItem('job_items', _apiData, _fullData)
            .pipe(
                switchMap(_material => {
                    return this.storageService
                        .getDataItem('jobs', _material.job_id)
                        .pipe(
                            switchMap(_job => {
                                _job.items.push(_material);
                                return this.storageService
                                    .updateDataItem('jobs', {}, _job, false)
                                    .pipe(map(() => _material.id));
                            }),
                        );
                }),
            );
    }
    /*
        update job item
    */
    updateMaterial(_apiData: JobItem, _fullData: JobItem): Observable<any> {
        return this.storageService
            .updateItem('job_items', _apiData, _fullData)
            .pipe(
                switchMap(_material => {
                    return this.storageService
                        .getDataItem('jobs', _material.job_id)
                        .pipe(
                            switchMap((_job: Job) => {
                                const _findIndex = _job.items.findIndex(
                                    _item => _item.id == _apiData.id,
                                );
                                if (_findIndex > -1) {
                                    _job.items[_findIndex] = _material;
                                }
                                return this.storageService
                                    .updateDataItem('jobs', {}, _job, false)
                                    .pipe(map(() => _material));
                            }),
                        );
                }),
            );
    }
    /*
        delete job item
    */
    deleteMaterial(_jobItem: JobItem): Observable<any> {
        return this.storageService.deleteItem('job_items', _jobItem.id).pipe(
            switchMap(() => {
                return this.storageService
                    .getDataItem('jobs', _jobItem.job_id)
                    .pipe(
                        switchMap((_job: Job) => {
                            const _findIndex = _job.items.findIndex(
                                _item => _item.id == _jobItem.id,
                            );
                            if (_findIndex > -1) {
                                _job.items.splice(_findIndex, 1);
                            }
                            return this.storageService.updateDataItem(
                                'jobs',
                                {},
                                _job,
                                false,
                            );
                        }),
                    );
            }),
        );
    }
    /*
        reate job message
    */
    createMessage(
        _apiData: JobMessage,
        _fullData: JobMessage,
    ): Observable<any> {
        return this.storageService
            .createItem('job_messages', _apiData, _fullData)
            .pipe(
                switchMap(_message => {
                    return this.storageService
                        .getDataItem('jobs', _apiData.job_id)
                        .pipe(
                            switchMap(_job => {
                                _job.messages.push(_message);
                                return this.storageService.updateDataItem(
                                    'jobs',
                                    {},
                                    _job,
                                    false,
                                );
                            }),
                        );
                }),
            );
    }
    /*
        update job tags
    */
    updateTags(_id: any, _tags: any[]): Observable<any> {
        return this.storageService.getDataItem('jobs', _id).pipe(
            switchMap(_job => {
                _job.tags = _tags;
                return this.storageService.updateDataItem(
                    'jobs',
                    {
                        id: _id,
                        tags: _tags.map(_item => _item.id),
                    },
                    _job,
                    true,
                );
            }),
        );
    }
}
