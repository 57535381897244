import { OnInit, OnDestroy, NgZone, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { TIME_EVENTS_TYPES } from '../../app.constant';
import { User, Job, TimeEvent, Document } from '../../app.interface';
import {
    JobService,
    EventService,
    UtilService,
    CacheService,
    RoleService,
} from '../../services';

@Component({
    selector: 'app-job-time-tracking-dialog',
    templateUrl: './job-time-tracking.dialog.html',
    styleUrls: [],
})
export class JobTimeTrackingDialog implements OnInit, OnDestroy {
    loading = false;
    job: Job;
    users: User[] = [];
    types = TIME_EVENTS_TYPES;
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private zone: NgZone,
        private snackbar: MatSnackBar,
        private jobService: JobService,
        private eventService: EventService,
        private utilService: UtilService,
        private roleService: RoleService,
        private translate: TranslateService,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<JobTimeTrackingDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        get documents
    */
    refreshData(): void {
        this.loading = true;
        this.spinner.show();
        const _syncIds = this.cacheService.getDataList('sync_ids');
        this.data.job.id = _syncIds[this.data.job.id]
            ? _syncIds[this.data.job.id]
            : this.data.job.id;
        this.jobService
            .getItemById(this.job.id)
            .pipe(
                finalize(() => {
                    this.zone.run(() => {
                        this.loading = false;
                        this.spinner.hide();
                    });
                }),
            )
            .subscribe((_job: Job) => {
                this.job = _job;
                this.groupTimeEvents();
            });
    }
    /*
        group timeevents by user
    */
    groupTimeEvents(): void {
        const _users = this.job.time_events.map(_event => _event.user);
        this.users = this.utilService.getUnique(_users, 'id');
        this.users.forEach((_user: User) => {
            _user.timeEvents = this.job.time_events.filter(
                (_event: TimeEvent) => _event.user.id == _user.id,
            );
            _user.timeOverview = this.utilService.getTimeEventsOverview(
                _user.timeEvents,
            );
        });
    }
    /*
        called when user click timeEvent item
    */
    editTimeEvent(_event: TimeEvent): void {
        if (
            this.roleService.hasPermission(RoleService.EDIT_ALL_TIME_EVENTS) ||
            this.roleService.hasPermission(RoleService.EDIT_OWN_TIME_EVENTS)
        ) {
            this.eventService.sendEvent('open_job_time_event_dialog', {
                job: this.job,
                timeEvent: _event,
                callback: result => {
                    if (result && result.refresh == true) {
                        this.refreshData();
                    }
                },
            });
        } else {
            this.snackbar.open(
                this.translate.instant('AlertMessage.NoPermission'),
                null,
                { duration: 2000 },
            );
        }
    }
    /*
        called when user click document item
    */
    selectDocument(_document: Document): void {
        this.eventService.sendEvent('open_document_preview_dialog', {
            document: _document,
            callback: null,
        });
    }
}
