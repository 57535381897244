// angular
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

// service
import { User } from '../app.interface';

import { CacheService } from './cache.service';
import { EventService } from './event.service';
import { RequestService } from './request.service';

@Injectable()
export class AuthService {
    token = '';
    constructor(
        private requestService: RequestService,
        private eventService: EventService,
        private cacheService: CacheService,
        private storage: Storage,
        private platform: Platform,
    ) {
        if (localStorage.getItem('app_token')) {
            this.token = localStorage.getItem('app_token');
        }
    }
    /*
        set token
    */
    setToken(_token: string): void {
        if (_token !== null) {
            localStorage.setItem('app_token', _token);
        }
        this.token = _token;
    }
    /*
        get token
    */
    getToken(): string {
        return this.token;
    }
    /*
        set device token
    */
    setDeviceToken(_token: string): void {
        localStorage.setItem('app_device_token', _token);
        this.postDeviceToken();
    }
    /*
        get device token
    */
    getDevoiceToken(): string {
        return localStorage.getItem('app_device_token');
    }
    /*
        send device token to server
    */
    postDeviceToken(): void {
        const _deviceToken = this.getDevoiceToken();
        if (this.token && _deviceToken) {
            let _client = 'android';
            if (this.platform.is('ios')) {
                _client = 'ios';
            } else if (this.platform.is('android')) {
                _client = 'android';
            }
            this.requestService
                .postRequest('/auth/devicetoken', {
                    client: _client,
                    token: _deviceToken,
                })
                .subscribe();
        }
    }
    /*
        set user information
    */
    setUserInfo(_user: User): void {
        localStorage.setItem('app_user', JSON.stringify(_user));
        this.eventService.sendEvent('updated_user', null);
    }
    /*
        get user information
    */
    getUserInfo(): User {
        const userInfo = JSON.parse(localStorage.getItem('app_user'));
        if (userInfo) {
            if (userInfo.image == null) {
                userInfo.image = 'assets/images/profile.png';
            }
        }
        return userInfo;
    }
    /*
        logout account
    */
    logout(): void {
        this.requestService.postRequest('/auth/logout', {}).subscribe();
        this.cacheService.clear();
        this.storage.clear();
        this.eventService.sendEvent('logged-out', null);
        this.setToken(null);
    }
    /*
        get profile
    */
    getProfile(): Observable<any> {
        return this.requestService
            .getParamRequest('/auth/profile', {})
            .pipe(tap(res => this.setUserInfo(res.profile)));
    }
    /*
        login account
    */
    login(_email: string, _password: string): Observable<any> {
        return this.requestService
            .postRequest('/auth/login', {
                email: _email,
                password: _password,
            })
            .pipe(
                tap(res => {
                    this.setToken(res.token);
                    this.postDeviceToken();
                }),
                switchMap(() => this.getProfile()),
            );
    }
    /*
        update profile
    */
    updateProfile(_profile: any): Observable<any> {
        return this.requestService
            .postRequest('/auth/profile', _profile)
            .pipe(tap(res => this.setUserInfo(res.profile)));
    }
    /*
        change password
    */
    changePassword(_old: string, _new: string): Observable<any> {
        return this.requestService
            .postRequest('/auth/password', {
                oldpassword: _old,
                newpassword: _new,
            })
            .pipe(
                tap(res => this.setToken(res.token)),
                switchMap(() => this.getProfile()),
            );
    }
    /*
        send password reset link
    */
    sendPasswordResetLink(_email: string): Observable<any> {
        return this.requestService.postRequest('/auth/password/resetlink', {
            email: _email,
        });
    }
    /*
        reset passowrd
    */
    resetPassword(_newPassword: string, _resetToken: string): Observable<any> {
        return this.requestService
            .postRequest('/auth/password/reset', {
                newpassword: _newPassword,
                resettoken: _resetToken,
            })
            .pipe(
                tap(res => this.setToken(res.token)),
                switchMap(() => this.getProfile()),
            );
    }
}
