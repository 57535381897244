import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
/*
    Cache Service
*/
@Injectable()
export class CacheService {
    constructor(private translate: TranslateService) {}

    clear(): void {
        const _lang = this.getLang();
        localStorage.clear();
        this.setLang(_lang);
    }

    getLang(): string {
        let _lang = localStorage.getItem('app_lang');
        _lang = _lang ? _lang : this.translate.getBrowserLang();
        localStorage.setItem('app_lang', _lang);
        return _lang;
    }

    setLang(_lang: string): void {
        localStorage.setItem('app_lang', _lang);
    }
    /*
        clear data list by key
    */
    clearDataList(_key: string): void {
        localStorage.removeItem('app_cache_' + _key);
    }
    /*
        return data list by key
    */
    getDataList(_key: string): any[] {
        return JSON.parse(localStorage.getItem('app_cache_' + _key)) || [];
    }
    /*
        set data list by key
    */
    setDataList(_key: string, _data: any): void {
        localStorage.setItem('app_cache_' + _key, JSON.stringify(_data));
    }
    /*
        get data item by key and id
    */
    getDataItem(_key: string, _id: string | number): any {
        const _list = this.getDataList(_key);
        const _findItem = _list.find(_item => _item.id == _id);
        return _findItem;
    }
    /*
        add data item by key
    */
    addDataItem(_key: string, _data: any): void {
        const _list = this.getDataList(_key);
        _list.push(_data);
        this.setDataList(_key, _list);
    }
    /*
        delete data item
    */
    deleteDataItem(_key: string, _id: string | number): void {
        const _list = this.getDataList(_key);
        const _findIndex = _list.findIndex(_item => _item.id == _id);
        if (_findIndex > -1) {
            _list.splice(_findIndex, 1);
        }
        this.setDataList(_key, _list);
    }
}
