import { OnInit, OnDestroy, NgZone, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

import { TimeEvent, Job, User } from '../../app.interface';
import {
    TimeEventService,
    EventService,
    UserService,
    UtilService,
    CacheService,
} from '../../services';

@Component({
    selector: 'app-job-time-event-dialog',
    templateUrl: './job-time-event.dialog.html',
    styleUrls: [],
})
export class JobTimeEventDialog implements OnInit, OnDestroy {
    loading = false;
    job: Job;
    timeEvent: TimeEvent;
    form: FormGroup;
    allUsers: User[] = [];
    filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        private zone: NgZone,
        private userService: UserService,
        private eventService: EventService,
        private utilService: UtilService,
        private timeEventService: TimeEventService,
        private cacheService: CacheService,
        public dialogRef: MatDialogRef<JobTimeEventDialog>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.eventService
            .getEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(payload => {
                if (payload.command == 'synchronizing_finished') {
                    this.refreshData();
                }
            });
    }

    ngOnInit(): void {
        this.refreshData();

        this.form = this.fb.group({
            user: [null, Validators.compose([Validators.required])],
            type: 1,
            start_date: [new Date(), Validators.compose([Validators.required])],
            start_time: [
                moment().format('HH:mm'),
                Validators.compose([Validators.required]),
            ],
            end_date: [new Date(), Validators.compose([Validators.required])],
            end_time: [
                moment().add(30, 'minutes').format('HH:mm'),
                Validators.compose([Validators.required]),
            ],
            distance: [null],
            notes: [null],
            //additional
            filterUser: [null],
        });

        // form value changes
        this.form.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(value => {
                if (value.type == 2) {
                    this.form.controls['distance'].setValidators([
                        Validators.required,
                    ]);
                } else {
                    this.form.controls['distance'].clearValidators();
                }
                this.form.controls['distance'].updateValueAndValidity({
                    onlySelf: true,
                });
                this.form.updateValueAndValidity({ emitEvent: false });
            });

        // listen for search field value changes
        this.form.controls['filterUser'].valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(_value => {
                this.utilService.filterList(
                    this.filteredUsers,
                    this.allUsers,
                    _value,
                    [],
                    'user',
                );
            });

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
        refresh data
    */
    refreshData(): void {
        this.loading = true;
        this.spinner.show();
        const _syncIds = this.cacheService.getDataList('sync_ids');
        if (this.data.job) {
            this.data.job.id = _syncIds[this.data.job.id]
                ? _syncIds[this.data.job.id]
                : this.data.job.id;
            this.job = this.data.job;
        }
        if (this.data.timeEvent) {
            this.data.timeEvent.id = _syncIds[this.data.timeEvent.id]
                ? _syncIds[this.data.timeEvent.id]
                : this.data.timeEvent.id;
            this.timeEvent = this.data.timeEvent;
        }

        this.userService
            .getList()
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.spinner.hide();
                }),
            )
            .subscribe((res: User[]) => {
                this.allUsers = res;
                this.utilService.filterList(
                    this.filteredUsers,
                    this.allUsers,
                    null,
                    [],
                    'user',
                );
                // opened to edit
                if (this.timeEvent?.id) {
                    this.loading = true;
                    this.spinner.show();
                    this.timeEventService
                        .getItemById(this.timeEvent.id)
                        .pipe(
                            finalize(() => {
                                this.zone.run(() => {
                                    this.loading = false;
                                    this.spinner.hide();
                                });
                            }),
                        )
                        .subscribe((_timeEvent: TimeEvent) => {
                            this.timeEvent = _timeEvent;
                            const _find = this.allUsers.find(
                                _user => _user.id == _timeEvent.user_id,
                            );
                            this.form.patchValue({
                                user: _find,
                                type: _timeEvent.type,
                                start_date: _timeEvent.start,
                                start_time: moment(_timeEvent.start).format(
                                    'HH:mm',
                                ),
                                end_date: _timeEvent.end,
                                end_time: moment(_timeEvent.end).format(
                                    'HH:mm',
                                ),
                                distance: _timeEvent.distance,
                                notes: _timeEvent.notes,
                            });
                        });
                }
            });
    }

    /*
        called when user click 'remove' button
    */
    remove(): void {
        this.loading = true;
        this.spinner.show();
        this.timeEventService
            .deleteItem(this.timeEvent.id)
            .pipe(
                finalize(() => {
                    this.zone.run(() => {
                        this.loading = false;
                        this.spinner.hide();
                    });
                }),
            )
            .subscribe(() => {
                this.dialogRef.close({
                    refresh: true,
                });
            });
    }

    onSubmit(): void {
        if (this.form.valid) {
            const formData = this.form.value;
            const _start =
                moment(formData.start_date).format('YYYY-MM-DD') +
                ' ' +
                formData.start_time;
            const _end =
                moment(formData.end_date).format('YYYY-MM-DD') +
                ' ' +
                formData.end_time;
            if (moment(_start).isSameOrAfter(moment(_end))) {
                this.eventService.sendEvent('open_alert_dialog', {
                    message: 'ValidationMessage.StartDateEndDate',
                    callback: null,
                });
            } else {
                this.loading = true;
                this.spinner.show();
                const timeEventData: TimeEvent = {
                    type: formData.type,
                    user_id: formData.user.id,
                    notes: formData.notes,
                    distance: formData.distance,
                    time_start: moment(_start)
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss'),
                    time_end: moment(_end).utc().format('YYYY-MM-DD HH:mm:ss'),
                    job_id: this.job.id,
                };
                if (this.timeEvent?.id) {
                    timeEventData['id'] = this.timeEvent.id;
                    this.timeEventService
                        .updateItem(timeEventData, timeEventData)
                        .pipe(
                            finalize(() => {
                                this.zone.run(() => {
                                    this.loading = false;
                                    this.spinner.hide();
                                });
                            }),
                        )
                        .subscribe(() => {
                            this.dialogRef.close({
                                refresh: true,
                            });
                        });
                } else {
                    this.timeEventService
                        .createItem(timeEventData, timeEventData)
                        .pipe(
                            finalize(() => {
                                this.loading = false;
                                this.spinner.hide();
                            }),
                        )
                        .subscribe(_id => {
                            this.dialogRef.close({
                                refresh: true,
                                id: _id,
                            });
                        });
                }
            }
        }
    }
    /*
        called when user click cancel button
    */
    cancel(): void {
        this.dialogRef.close();
    }
}
