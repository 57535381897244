import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { JOB_STATES } from '../../app.constant';
import { Job } from '../../app.interface';

@Component({
    selector: 'app-job-state-dialog',
    templateUrl: './job-state.dialog.html',
    styleUrls: [],
})
export class JobStateDialog implements OnInit, OnDestroy {
    loading = false;
    job: Job;
    states = JOB_STATES;
    config: PerfectScrollbarConfigInterface = {};
    protected _onDestroy = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<JobStateDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.job = this.data.job;

        this.dialogRef
            .keydownEvents()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(event => {
                if (event.key === 'Escape') this.dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    /*
        called when user click state item
    */
    selectState(_state: string): void {
        this.dialogRef.close({
            refresh: true,
            state: _state,
        });
    }
}
