// angular
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// service
import {
    Country,
    Language,
    TimeEvent,
    TimeOverview,
    Appointment,
    Checklist,
    ChecklistStep,
    Product,
} from '../app.interface';

import { ConfigService } from './config.service';
import { RequestService } from './request.service';

@Injectable()
export class UtilService {
    languages: Language[] = [];
    countries: Country[] = [];
    constructor(private requestService: RequestService) {}
    /*
        check mobile
    */
    isMobile(): boolean {
        /* eslint-disable no-useless-escape */
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4),
            )
        ) {
            return true;
        }
        return false;
        /* eslint-enable no-useless-escape */
    }
    /*
        get language list
    */
    getLanguages(): Observable<Language[]> {
        if (this.languages.length > 0) {
            return of(this.languages);
        } else {
            return this.requestService.getParamRequest('/languages', {}).pipe(
                map(res => {
                    this.languages = res.languages;
                    return res.languages;
                }),
            );
        }
    }
    /*
        get countries list
    */
    getCountries(): Observable<Country[]> {
        if (this.countries.length > 0) {
            return of(this.countries);
        } else {
            return this.requestService.getParamRequest('/countries', {}).pipe(
                map(res => {
                    this.countries = res.countries;
                    return res.countries;
                }),
            );
        }
    }
    /*
        get available language list
    */
    getAvailableLanguages(): Observable<Language[]> {
        return of(ConfigService.settings.i18n.availableLanguages);
    }
    /*
        upload image
    */
    uploadImage(_image: File): Observable<string> {
        const _data = new FormData();
        _data.append('file', _image);
        return this.requestService
            .postFormRequest('/upload/image', _data)
            .pipe(map(res => res.url));
    }
    /*
        get filtered list
    */
    filterList(
        _filtered: any,
        _list: any[],
        _search: any,
        _except: any[] = [],
        prop = 'name',
    ): void {
        if (_list.length == 0) {
            _filtered.next([]);
        }

        if (typeof _search === 'string' || _search instanceof String) {
            _search = _search.toLowerCase();
            let _filtedByProp = [];
            if (prop == 'user') {
                _filtedByProp = _list.filter(
                    item =>
                        item.contact.name.toLowerCase().indexOf(_search) > -1,
                );
            } else {
                _filtedByProp = _list.filter(
                    item => item[prop].toLowerCase().indexOf(_search) > -1,
                );
            }
            // filter
            _filtered.next(
                _filtedByProp.filter(item => {
                    const _duplicated = _except.find(_ditem => {
                        return _ditem.id == item.id;
                    });
                    return !_duplicated;
                }),
            );
        } else {
            _filtered.next(
                _list.slice().filter(_item => {
                    const _duplicated = _except.find(_ditem => {
                        return _ditem.id == _item.id;
                    });
                    return !_duplicated;
                }),
            );
        }
    }
    /*
        convert base64String data to blob and return
        @parameter
            b64Data: Base64String,
            contentType: MimeType,
            sliceSize: SliceSize
    */
    b64ToBlob(b64Data: string, contentType = '', sliceSize = 512): any {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    /*
        return address data from google place api response
    */
    placeToAddress(_place: any): any {
        let _address = '',
            _city = '',
            _zip = '',
            _country = '';
        _address = _place.formatted_address;
        for (const addressComponent of _place.address_components) {
            for (const addressComponentType of addressComponent.types) {
                if (addressComponentType == 'postal_code') {
                    _zip = addressComponent.long_name;
                    _address = _address.replace(', ' + _zip, '');
                    _address = _address.replace(' ' + _zip, '');
                }
                if (addressComponentType == 'postal_town') {
                    _city = addressComponent.long_name;
                }
                if (addressComponentType == 'locality') {
                    _city = addressComponent.long_name;
                }
                if (
                    addressComponentType == 'sublocality_level_1' &&
                    _city == ''
                ) {
                    _city = addressComponent.long_name;
                }
                if (addressComponentType == 'country') {
                    _country = addressComponent.short_name;
                    _address = _address.replace(
                        ', ' + addressComponent.long_name,
                        '',
                    );
                    _address = _address.replace(
                        ' ' + addressComponent.long_name,
                        '',
                    );
                }
            }
        }
        _address = _address.replace(', ' + _city, '');
        _address = _address.replace(' ' + _city, '');
        return {
            address: _address,
            zip: _zip,
            city: _city,
            country: _country,
        };
    }
    /*
        adjust time event
    */
    adjustTimeEvent(_event: TimeEvent): void {
        const time_start = moment(_event.time_start);
        const time_end = moment(_event.time_end);
        const duration = moment.duration(time_end.diff(time_start));
        _event.duration = duration;
        _event.start = new Date(_event.time_start);
        _event.end = new Date(_event.time_end);
        _event.totalHours = parseFloat(duration.asHours().toFixed(2));
        const _totalHours = Math.floor(duration.asHours());
        _event.formattedTotalHours =
            (_totalHours < 10 ? '0' + _totalHours : _totalHours) +
            ':' +
            moment.utc(duration.asMilliseconds()).format('mm:ss');
        if (_event.distance) {
            _event.distance = parseFloat(_event.distance.toFixed(2));
        } else {
            _event.distance = 0;
        }
    }
    /*
        adjust appointment
    */
    adjustAppointment(_appointment: Appointment): void {
        _appointment.from = new Date(_appointment.time_from);
        _appointment.to = new Date(_appointment.time_to);
    }
    /*
        adjust time overview
    */
    adjustTimeOverview(_overview: TimeOverview): void {
        const _workingTimeHours = Math.floor(
            moment.duration(_overview.workingtime, 'hours').asHours(),
        );
        _overview.formattedWorkingtime =
            (_workingTimeHours < 10
                ? '0' + _workingTimeHours
                : _workingTimeHours) +
            ':' +
            moment
                .utc(
                    moment
                        .duration(_overview.workingtime, 'hours')
                        .asMilliseconds(),
                )
                .format('mm:ss');
        const _drivingTimeHours = Math.floor(
            moment.duration(_overview.drivingtime, 'hours').asHours(),
        );
        _overview.formattedDrivingtime =
            (_drivingTimeHours < 10
                ? '0' + _drivingTimeHours
                : _drivingTimeHours) +
            ':' +
            moment
                .utc(
                    moment
                        .duration(_overview.drivingtime, 'hours')
                        .asMilliseconds(),
                )
                .format('mm:ss');
        const _companyTimeHours = Math.floor(
            moment.duration(_overview.companytime, 'hours').asHours(),
        );
        _overview.formattedCompanytime =
            (_companyTimeHours < 10
                ? '0' + _companyTimeHours
                : _companyTimeHours) +
            ':' +
            moment
                .utc(
                    moment
                        .duration(_overview.companytime, 'hours')
                        .asMilliseconds(),
                )
                .format('mm:ss');
        const _totalHours = Math.floor(
            moment.duration(_overview.totalhours, 'hours').asHours(),
        );
        _overview.formattedTotalHours =
            (_totalHours < 10 ? '0' + _totalHours : _totalHours) +
            ':' +
            moment
                .utc(
                    moment
                        .duration(_overview.totalhours, 'hours')
                        .asMilliseconds(),
                )
                .format('mm:ss');
        _overview.distance = parseFloat(_overview.distance.toFixed(2));
    }
    /*
        get over view from time events
    */
    getTimeEventsOverview(_events: TimeEvent[]): TimeOverview {
        const _overview: TimeOverview = {};
        const _duration = moment.duration(0);
        const _workingTimeDuration = moment.duration(0);
        const _drivingTimeDuration = moment.duration(0);
        let _distance = 0;
        _events.forEach((_event: TimeEvent) => {
            _duration.add(_event.duration);
            if (_event.type == 1) {
                _workingTimeDuration.add(_event.duration);
            } else if (_event.type == 2) {
                _drivingTimeDuration.add(_event.duration);
                _distance = _distance + _event.distance;
            }
        });
        _overview.workingtime = _workingTimeDuration.asHours();
        _overview.drivingtime = _drivingTimeDuration.asHours();
        const _workingTimeHours = Math.floor(_workingTimeDuration.asHours());
        _overview.formattedWorkingtime =
            (_workingTimeHours < 10
                ? '0' + _workingTimeHours
                : _workingTimeHours) +
            ':' +
            moment.utc(_workingTimeDuration.asMilliseconds()).format('mm:ss');
        const _drivingTimeHours = Math.floor(_drivingTimeDuration.asHours());
        _overview.formattedDrivingtime =
            (_drivingTimeHours < 10
                ? '0' + _drivingTimeHours
                : _drivingTimeHours) +
            ':' +
            moment.utc(_drivingTimeDuration.asMilliseconds()).format('mm:ss');
        const _totalHours = Math.floor(_duration.asHours());
        _overview.formattedTotalHours =
            (_totalHours < 10 ? '0' + _totalHours : _totalHours) +
            ':' +
            moment.utc(_duration.asMilliseconds()).format('mm:ss');
        _overview.distance = parseFloat(_distance.toFixed(2));
        return _overview;
    }
    /*
        make flat array to tree structure
    */
    getNestedChildren(
        _array: ChecklistStep[],
        _parent: number,
    ): ChecklistStep[] {
        const _tree = [];
        for (const i in _array) {
            if (_array[i].parent_step == _parent) {
                let _children: any[] = [];
                _children = this.getNestedChildren(_array, _array[i].id);
                _children.sort((a: any, b: any) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
                );
                _array[i].children = _children;
                _tree.push(_array[i]);
            }
        }
        return _tree;
    }
    /*
        make nested array to flat array
    */
    getFlat(_array: ChecklistStep[]): ChecklistStep[] {
        let _result = [];
        _array.forEach((_step: ChecklistStep) => {
            _result.push(_step);
            if (Array.isArray(_step.children)) {
                _result = _result.concat(this.getFlat(_step.children));
            }
        });
        return _result;
    }
    /*
        find node
    */
    findNode(_array: ChecklistStep[], _id: number): any {
        let _result = null;
        for (const i in _array) {
            if (_array[i].id == _id) {
                _result = _array[i];
                break;
            } else if (Array.isArray(_array[i].children)) {
                _result = this.findNode(_array[i].children, _id);
                if (_result != null) {
                    break;
                }
            }
        }
        return _result;
    }
    /*
        remove node
    */
    removeNode(parent: Checklist | Product | ChecklistStep, _id: number): any {
        parent.children = parent.children
            .filter(_step => {
                return _step.id !== _id;
            })
            .map(_step => {
                return this.removeNode(_step, _id);
            });
        return parent;
    }
    /*
        check answered
    */
    checkStepAnswered(_step: any): void {
        _step.answered = false;
        const _selected = _step.checklist_step_choices?.find(
            _choice => _choice.selected == 1,
        );
        const _noAnswerd = _step.checklist_step_values?.find(
            _value => _value.answer == null,
        );
        switch (_step.type) {
            case 1: // choice
                if (_selected) {
                    _step.answered = true;
                }
                break;
            case 2: // value
                if (!_noAnswerd) {
                    _step.answered = true;
                }
                break;
            case 3: // material
                _step.answered = true;
                break;
            case 4: // photo
                if (_step.checklist_step_attachments.length > 0) {
                    _step.answered = true;
                }
                break;
            case 5: // group
                _step.answered = true;
                _step.checklist_steps = this.getFlat(_step.children);
                _step.checklist_steps.forEach(_item => {
                    if (_item.type != 5) {
                        this.checkStepAnswered(_item);
                        if (_item.answered == false) {
                            _step.answered = false;
                        }
                    }
                });
                break;
            case 6: // signature
                if (_step.checklist_step_attachments.length > 0) {
                    _step.answered = true;
                }
                break;
            default:
                // code...
                break;
        }
    }
    /*
        get unique element on array
    */
    getUnique(_array: any[], _key: string): any[] {
        const unique = _array
            .map(e => e[_key])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => _array[e])
            .map(e => _array[e]);

        return unique;
    }
}
