import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import { CacheService } from './cache.service';
import { EventService } from './event.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private eventService: EventService,
        private cacheService: CacheService,
        private storage: Storage,
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = localStorage.getItem('app_token');

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        return next.handle(request).pipe(
            tap(
                () => {
                    // TODO handle param event: HttpEvent<T>
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                err.error,
                                'message',
                            )
                        ) {
                            this.snackBar.open(err.error.message, null, {
                                duration: 3000,
                            });
                        } else {
                            this.translateService
                                .get('AlertMessage.RequestError')
                                .subscribe((res: string) => {
                                    this.snackBar.open(res, null, {
                                        duration: 3000,
                                    });
                                });
                        }
                        if (err.status === 401) {
                            this.refresh();
                        }
                    }
                },
            ),
        );
    }

    refresh(): void {
        setTimeout(() => {
            this.dialog.closeAll();
            this.cacheService.clear();
            this.storage.clear();
            this.eventService.sendEvent('logged-out', null);
            this.router.navigate(['/', 'login'], { replaceUrl: true });
        }, 500);
    }
}
