import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
} from 'ng-pick-datetime-ex';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';

import { NgxExtendedPdfViewerModule } from '../plugins/ngx-extended-pdf-viewer/public_api';

import { MaterialModule } from './material.module';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        NgxMaterialTimepickerModule,
        NgxMatSelectSearchModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        NgxExtendedPdfViewerModule,
        IonicModule,
        NgxSpinnerModule,
    ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        NgxMaterialTimepickerModule,
        NgxMatSelectSearchModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        NgxExtendedPdfViewerModule,
        IonicModule,
        NgxSpinnerModule,
    ],
})
export class SharedModule {}
