// angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// service
import { Checklist } from '../app.interface';

import { RequestService } from './request.service';
import { StorageService } from './storage.service';

@Injectable()
export class ChecklistService {
    constructor(
        private requestService: RequestService,
        private storageService: StorageService,
    ) {}
    /*
        get all checklists
    */
    getAll(): Observable<Checklist[]> {
        const params = new URLSearchParams();
        return this.requestService
            .getParamRequest('/checklist/all?' + params.toString(), {})
            .pipe(map(res => res.checklists));
    }
    /*
        get checklist list
    */
    getList(): Observable<Checklist[]> {
        return this.storageService.getDataList('checklists');
    }
    /*
        get checklist by id
    */
    getItemById(_id: any): Observable<Checklist> {
        return this.storageService.getDataItem('checklists', _id).pipe(
            tap(res => {
                res.checklist_steps.forEach(_step => {
                    if (_step.type == 1) {
                        _step.checklist_step_choices.sort((a: any, b: any) =>
                            a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0,
                        );
                    }
                });
            }),
        );
    }
}
