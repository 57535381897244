import { NgModule } from '@angular/core';

// pipes
import { LocalDatePipe } from './local-date.pipe';

@NgModule({
    imports: [],
    declarations: [LocalDatePipe],
    exports: [LocalDatePipe],
})
export class PipesModule {}
