import { NgModule } from '@angular/core';

import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';

import { HeaderComponent } from './header/header.component';
import { SearchComponent } from './search/search.component';
import { SpinnerComponent } from './spinner.component';

@NgModule({
    imports: [SharedModule, PipesModule],
    declarations: [HeaderComponent, SearchComponent, SpinnerComponent],
    exports: [HeaderComponent, SearchComponent, SpinnerComponent],
})
export class ComponentsModule {}
