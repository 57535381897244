// angular
import { Injectable } from '@angular/core';

// service
import { AuthService } from './auth.service';

@Injectable()
export class RoleService {
    public static VIEW_OWN_JOBS = 1;
    public static VIEW_ALL_JOBS = 2;
    public static EDIT_OWN_JOBS = 3;
    public static EDIT_ALL_JOBS = 4;
    public static CLOSE_JOBS = 5;
    public static DELETE_JOBS = 6;
    public static REPOEN_JOBS = 7;
    public static CREATE_NEW_JOBS = 8;
    public static VIEW_OWN_CONTACTS = 9;
    public static VIEW_ALL_CONTACTS = 10;
    public static EDIT_OWN_CONTACTS = 11;
    public static EDIT_ALL_CONTACTS = 12;
    public static CREATE_CONTACTS = 13;
    public static VIEW_ALL_CUSTOMERS = 14;
    public static VIEW_OWN_CUSTOMERS = 15;
    public static EDIT_ALL_CUSTOMERS = 16;
    public static EDIT_OWN_CUSTOMERS = 17;
    public static CREATE_NEW_CUSTOMERS = 18;
    public static VIEW_OWN_SERVICE_OBJECTS = 19;
    public static VIEW_ALL_SERVICE_OBJECTS = 20;
    public static EDIT_OWN_SERVICE_OBJECTS = 21;
    public static EDIT_ALL_SERVICE_OBJECTS = 22;
    public static CREATE_NEW_SERVICE_OBJECTS = 23;
    public static VIEW_MAINTENANCE_EVENTS = 24;
    public static EDIT_AND_CREATE_MAINTENANCE_EVENTS = 25;
    public static VIEW_PRODUCTS = 26;
    public static EDIT_AND_CREATE_PRODUCTS = 27;
    public static VIEW_DOCUMENTS_AND_REPORTS = 28;
    public static EDIT_AND_CREATE_DOCUMENTS_AND_REPORTS = 29;
    public static VIEW_ITEMS = 30;
    public static EDIT_AND_CREATE_ITEMS = 31;
    public static VIEW_CHECKLISTS = 32;
    public static EDIT_AND_CREATE_CHECKLISTS = 33;
    public static VIEW_TEMPLATE_JOBS = 34;
    public static EDIT_AND_CREATE_TEMPLATE_JOBS = 35;
    public static VIEW_OWN_TIME_EVENTS = 36;
    public static VIEW_ALL_TIME_EVENTS = 37;
    public static EDIT_OWN_TIME_EVENTS = 38;
    public static EDIT_ALL_TIME_EVENTS = 39;
    public static CREATE_TIME_EVENTS = 40;
    public static VIEW_REPORT_TEMPLATES = 41;
    public static EDIT_AND_CREATE_REPORT_TEMPLATES = 42;
    public static VIEW_MAIL_TEMPLATES = 43;
    public static EDIT_AND_CREATE_MAIL_TEMPLATES = 44;
    public static VIEW_USERS = 45;
    public static EDIT_AND_CREATE_USERS = 46;
    public static VIEW_ROLES = 47;
    public static EDIT_AND_CREATE_ROLES = 48;
    public static VIEW_ALL_INVOICES = 49;
    public static VIEW_OWN_INVOICES = 50;
    public static EDIT_ALL_INVOICES = 51;
    public static EDIT_OWN_INVOICES = 52;

    constructor(private authService: AuthService) {}
    /*
        check has permission
    */
    hasPermission(_permission: number): boolean {
        const _userInfo = this.authService.getUserInfo();
        if (_userInfo.permissions.includes(_permission)) {
            return true;
        } else {
            return false;
        }
    }
}
